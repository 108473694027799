import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { FERequest, GetDepositParams, CommissionDataCommon, DirectionData, RateCommon } from 'src/app/api/fast-exchange/fast-exchange.model';
import { ParamsFEGetDepositParams, ParamsCommissionData, ParamsFEDirectionData, ParamsFERate, ParamsFEValidateWallet } from 'src/app/core/models/fast-exchange';

export namespace FastExchangeActions {
  // loadSearchInfoRequest => getSearchInfoRequest
  export const getSearchInfoRequest = createAction('[FE] get search info request', props<{ payload: string }>());
  export const getSearchInfoRequestSuccess = createAction('[FE] get search info request success', props<{ payload: FERequest }>());
  export const getSearchInfoRequestFailure = createAction('[FE] get search info request failure');
  export const getSearchInfoRequestReset = createAction('[FE]  search info request reset');

  // checkExchangeDirection => getDirectionIsPresent
  export const getDirectionIsPresent = createAction('[FE] get directions is present');
  export const getDirectionIsPresentSuccess = createAction('[FE] get directions is present success', props<{ payload: boolean }>());
  export const getDirectionIsPresentFailure = createAction('[FE] get directions is present failure');

  // loadInfoRequest => getRequestInfo
  export const getRequestInfo = createAction('[FE] get request info', props<{ payload: string }>());
  export const getRequestInfoSuccess = createAction('[FE] get request info success', props<{ payload: FERequest }>());
  export const getRequestInfoFailure = createAction('[FE] get request info failure');

  // confirmPayment => putConfirmPayment
  export const putConfirmPayment = createAction('[FE] put confirm payment', props<{ payload: number }>());
  export const putConfirmPaymentSuccess = createAction('[FE] put confirm payment success', props<{ payload: any }>());
  export const putConfirmPaymentFailure = createAction('[FE] put confirm payment failure');

  // getDepositParams => getDepositParams
  export const getDepositParams = createAction('[FE] get deposit params', props<{ payload: ParamsFEGetDepositParams }>());
  export const getDepositParamsSuccess = createAction('[FE] get deposit params success', props<{ payload: GetDepositParams }>());
  export const getDepositParamsFailure = createAction('[FE] get deposit params failure');

  // getCommissionData => getCommissionData
  export const getCommissionData = createAction('[FE] get commission data', props<{ payload: ParamsCommissionData }>());
  export const getCommissionDataSuccess = createAction('[FE] get commission data success', props<{ payload: CommissionDataCommon }>());
  export const getCommissionDataFailure = createAction('[FE] get commission data failure', props<{ error: HttpErrorResponse }>());
  export const getCommissionDataResetData = createAction('[FE] reset commission data');

  // getDirectionsData => getDirectionsData
  export const getDirectionsData = createAction('[FE] get directions data', props<{ payload?: ParamsFEDirectionData }>());
  export const getDirectionsDataSuccess = createAction('[FE] get directions data success', props<{ payload: DirectionData[] }>());
  export const getDirectionsDataFailure = createAction('[FE] get directions data failure');
  export const directionsDataReset = createAction('[FE] reset directions data');

  // getCurrentRate => getCurrentRate
  export const getCurrentRate = createAction('[FE] get current rate', props<{ payload: ParamsFERate }>());
  export const getCurrentRateSuccess = createAction('[FE] get current rate success', props<{ payload: RateCommon | null }>());
  export const getCurrentRateFailure = createAction('[FE] get current rate failure');

  // validateWallet => getValidateAddress
  export const getValidateAddress = createAction('[FE] get validate address', props<{ payload: ParamsFEValidateWallet }>());
  export const getValidateAddressSuccess = createAction('[FE] get validate address success', props<{ payload: boolean }>());
  export const getValidateAddressFailure = createAction('[FE] get validate address failure');
  export const getValidateAddressReset = createAction('[FE] validate address reset');

  export const cleanStore = createAction('[FE] clean Store');
  }
