import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { fastExchangeReducer } from './fast-exchange.reducer';
import { FastExchangeEffects } from './fast-exchange.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([FastExchangeEffects]), StoreModule.forFeature('fastExchange', fastExchangeReducer)],
})
export class FastExchangeStoreModule {}
