import { Pipe, PipeTransform } from '@angular/core';
import { RequestStatusFE, DepositStatusFE, WithdrawStatusFE } from 'src/app/core/enums/fast-exchange.enum';

@Pipe({
  name: 'feRequestStatusTitle',
  standalone: true,
})

export class FERequestStatusTitle implements PipeTransform {
  transform(requestStatus: RequestStatusFE, depositStatus: DepositStatusFE, withdrawStatus: WithdrawStatusFE): string {
    const statusRevoked = requestStatus === RequestStatusFE.AUTO_REVOKED ||
      requestStatus === RequestStatusFE.REVOKED_BY_ADMIN ||
      requestStatus === RequestStatusFE.REVOKED_BY_ACCOUNT ||
      depositStatus === DepositStatusFE.AUTO_REVOKED ||
      depositStatus === DepositStatusFE.REVOKED_BY_ADMIN ||
      depositStatus === DepositStatusFE.REVOKED_BY_ACCOUNT ||
      withdrawStatus === WithdrawStatusFE.AUTO_REVOKED ||
      withdrawStatus === WithdrawStatusFE.REVOKED_BY_ADMIN ||
      withdrawStatus === WithdrawStatusFE.REVOKED_BY_ACCOUNT;

    const statusExpired = requestStatus === RequestStatusFE.EXPIRED || depositStatus === DepositStatusFE.EXPIRED;
    const statusDepositFailed = depositStatus === DepositStatusFE.AUTO_REVOKED || depositStatus === DepositStatusFE.REVOKED_BY_ADMIN || depositStatus === DepositStatusFE.REVOKED_BY_ACCOUNT || statusExpired;
    const statusDepositInProgress = depositStatus === DepositStatusFE.PAID_UNCONFIRMED && !statusDepositFailed;
    const statusDepositSuccess = depositStatus === DepositStatusFE.COMPLETED;
    const statusWithdrawFailed = statusRevoked || statusExpired;
    const statusWithdrawSuccess = withdrawStatus === WithdrawStatusFE.COMPLETED;

    if (statusExpired) {
      return 'end_time';
    }
    if (statusDepositInProgress) {
      return 'waiting';
    }
    if ((statusRevoked || statusDepositFailed || statusWithdrawFailed) && !statusExpired) {
      return 'cancel';
    }
    if (statusDepositSuccess !== statusWithdrawSuccess) {
      return 'send';
    }
    if (statusWithdrawSuccess) {
      return 'success';
    }
    return 'create';
  }
}
