<form>
  <mat-select #matSelect [panelClass]="inputPanelClass" [formControl]="controlSelect" #singleSelect class="form-control organisme">
    <mat-option *ngIf="(selectArray && selectArray.length > 4) || true">
      <ngx-mat-select-search [formControl]="controlSearch" [placeholderLabel]="'shared.search' | translate">
        <span ngxMatSelectSearchClear>
          <img src="assets/images/svg/icons/close-icons.svg" alt="clear" />
        </span>
        <span class="text-lg text-white" ngxMatSelectNoEntriesFound>
          {{ 'shared.no_matches' | translate }}
        </span>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option [value]="selectItem" *ngFor="let selectItem of selectArray" (click)="chooseCurrency(selectItem)">
      <div *ngIf="selectItem.selectData" class="flex items-start justify-between py-2">
        <div class="flex items-center">
          <img class="flex h-[29px] w-[29px]" [src]="'assets/images/currency/' + selectItem.selectData.icon.toLowerCase() + '.svg'" alt="btc" />
          <span class="ml-[6px] text-xs leading-none text-white">{{ selectItem.selectData.currency }}</span>
          <span class="ml-[6px] text-xs leading-none text-white opacity-40">{{ selectItem.selectData.currencyName }}</span>
        </div>
        <div class="flex flex-col" (click)="$event.stopPropagation()">
          <div class="flex flex-col mt-[6px] relative">
            <button
              class="relative ml-auto flex items-center bg-main-purple-500 p-[3px]"
              [class.mb-2]="!(selectItem.selectData.merchantList?.length < 2)"
              type="button"
              (click)="selectItem.selectData.openNetworkSelect = !selectItem.selectData.openNetworkSelect">
              <span class="text-xs leading-none text-white">{{ (selectedDirection.selectData.currency === selectItem.selectData.currency) ? (selectedDirection.selectData.merchant | transformNetworkToDefaultType: selectedDirection.selectData.currency) : (selectItem.selectData.merchant | transformNetworkToDefaultType: selectItem.selectData.currency) }}</span>
              <img
                [class.hidden]="selectItem.selectData.merchantList?.length < 2"
                class="ml-[3px] h-[7px] w-[9px] rotate-90"
                src="assets/icons/arrow.svg"
                [class.!rotate-180]="!selectItem.selectData.openNetworkSelect"
                alt="" />
            </button>
            <ul class="absolute left-0 top-5 bg-main-purple-800 py-1 z-10 w-full" [class.hidden]="!selectItem.selectData.openNetworkSelect || selectItem.selectData.merchantList?.length < 2">
              <li [class.text-purple-400]="(selectedDirection.selectData.id === selectItem.selectData.id) && selectedDirection.selectData.merchant === network" (click)="chooseNetwork(selectItem, network)" *ngFor="let network of selectItem.selectData.merchantList" class="mb-[11px] pl-[3px] text-xs leading-none text-white last:mb-0 hover:text-purple-400">{{ network | transformNetworkToDefaultType: selectItem.selectData.currency }}</li>
            </ul>
          </div>
        </div>
      </div>
    </mat-option>
  </mat-select>
</form>
