<div class="flex min-w-[80vw] flex-col items-center md:min-w-[566px]">
  <div class="z-[2] mb-[-43px] flex h-[60px] w-[60px] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-800 sm:h-[86px] sm:w-[86px]">
    <span (click)="closeDialog(false)" class="flex h-[44px] w-[44px] cursor-pointer flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-300 p-[14px] sm:h-[64px] sm:w-[64px] sm:p-0">
      <img ngSrc="assets/images/svg/icons/close-icons.svg" alt="" height="24" width="24" />
    </span>
  </div>
  <div
    class="flex w-full flex-col items-center gap-[25px] rounded-[30px] bg-main-dark-blue-300 px-[25px] pb-[25px] pt-[50px] text-center text-[12px] sm:px-[40px] sm:pb-[40px] sm:pt-[65px] sm:text-[16px] lg:gap-[30px]">
    <div class="text-center text-[24px] font-semibold sm:text-[32px]">
      {{ 'modals.cancel_app.title' | translate }}
    </div>
    <span>{{ 'modals.cancel_app.text' | translate }}</span>
    <div class="flex items-center justify-center gap-[12px]">
      <button (click)="closeDialog(true)" class="btn btn-purple-bordered min-w-[90px] !text-main-purple-400 hover:!text-white">
        {{ 'modals.cancel_app.button_title.yes' | translate }}
      </button>
      <button (click)="closeDialog(false)" class="btn btn-primary min-w-[90px]">{{ 'modals.cancel_app.button_title.no' | translate }}</button>
    </div>
  </div>
</div>
