import { Component } from '@angular/core';

@Component({
  selector: 'app-our-advantages',
  templateUrl: './our-advantages.component.html',
  styleUrls: ['./our-advantages.component.scss'],
})
export class OurAdvantagesComponent {
  advantages: any[] = [
    {
      icon: 'assets/images/svg/icons/advantages-icon-1.svg',
      title: 'array_title_one',
      text: 'array_text_one',
    },
    {
      icon: 'assets/images/svg/icons/advantages-icon-2.svg',
      title: 'array_title_two',
      text: 'array_text_two',
    },
    {
      icon: 'assets/images/svg/icons/advantages-icon-3.svg',
      title: 'array_title_three',
      text: 'array_text_three',
    },
    {
      icon: 'assets/images/svg/icons/advantages-icon-4.svg',
      title: 'array_title_four',
      text: 'array_text_four',
    },
    {
      icon: 'assets/images/svg/icons/advantages-icon-5.svg',
      title: 'array_title_five',
      text: 'array_text_five',
    },
  ];
}
