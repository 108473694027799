<section>
  <div class="container">
    <div class="relative flex flex-col rounded-[20px] bg-main-dark-blue-500 shadow-slide ml:flex-row">
      <div class="grow-1 relative w-full flex-shrink-0 basis-1/2">
        <div
          class="absolute bottom-[-20px] right-[50%] z-[2] flex h-[40px] w-[40px] translate-x-2/4 rotate-[90deg] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-500 p-[15px] ml:right-[0] ml:top-[50%] ml:h-[60px] ml:w-[60px] ml:-translate-y-2/4 ml:rotate-[0deg] 2xl:h-[100px] 2xl:w-[100px]">
          <img ngSrc="assets/images/svg/icons/arrow-small.svg" alt="" height="22" width="14" />
        </div>
        <img
          class="h-full w-full rounded-t-[20px] object-cover ml:absolute ml:bottom-0 ml:left-0 ml:right-0 ml:top-0 ml:rounded-l-[20px] ml:rounded-t-[0]"
          ngSrc="assets/images/integrate-img-1.jpg"
          alt=""
          height="669"
          width="812" />
      </div>
      <div
        class="xl:[18px] flex basis-1/2 flex-col items-center gap-[25px] p-[25px] text-center text-[16px] leading-[1.40] text-white/70 md:py-[40px] ml:items-start ml:px-[50px] ml:text-left xl:gap-[30px] 2xl:gap-[38px] 2xl:px-[105px] 2xl:py-[103px] 2xl:text-[21px]">
        <div class="title_big text-white">
          <span class="font-bold"> {{ 'home.integration.api.title_text_bold' | translate }}</span>
          {{ 'home.integration.api.title_text_light' | translate }}
        </div>
        <p>
          {{ 'home.integration.api.title_text_paragraph_1' | translate }}
        </p>
        <p class="font-semibold bg-main-purple-700 text-white py-[2px] px-1 flex items-center justify-center rounded"><span class="leading-0">{{ 'home.integration.api.soon' | translate }}</span></p>
        <!-- <p>
          {{ 'home.integration.api.title_text_paragraph_2' | translate }}
          <a class="purple !no-underline" target="_blank" href="#"> {{ 'home.integration.api.title_api_link' | translate }}</a>
        </p> -->
      </div>
    </div>
  </div>
  <div class="container mt-[48px] ml:mt-[60px] lg:mt-[80] xl:mt-[105px]">
    <div class="relative flex flex-col-reverse rounded-[20px] bg-main-dark-blue-500 shadow-slide ml:flex-row">
      <div
        class="xl:[18px] flex basis-1/2 flex-col items-center gap-[25px] p-[25px] text-center text-[16px] leading-[1.40] text-white/70 md:py-[40px] ml:items-start ml:px-[50px] ml:text-left xl:gap-[30px] 2xl:gap-[38px] 2xl:px-[105px] 2xl:py-[100px] 2xl:text-[21px]">
        <div class="title_big text-white">
          <span class="font-bold">
            {{ 'home.integration.become.title_text_bold' | translate }}
          </span>
          {{ 'home.integration.become.title_text_light' | translate }}
        </div>
        <p>
          {{ 'home.integration.become.title_text_paragraph_1' | translate }}
        </p>
        <p class="font-semibold bg-main-purple-700 text-white py-[2px] px-1 flex items-center justify-center rounded"><span class="leading-0">{{ 'home.integration.become.soon' | translate }}</span></p>
        <!-- <p>
          {{ 'home.integration.become.title_text_paragraph_2' | translate }}
        </p> -->
      </div>
      <div class="grow-1 relative w-full flex-shrink-0 basis-1/2">
        <div
          class="absolute bottom-[-20px] right-[50%] z-[2] flex h-[40px] w-[40px] translate-x-2/4 rotate-[90deg] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-500 p-[15px] ml:right-full ml:top-[50%] ml:h-[60px] ml:w-[60px] ml:-translate-y-2/4 ml:rotate-[180deg] 2xl:h-[100px] 2xl:w-[100px]">
          <img ngSrc="assets/images/svg/icons/arrow-small.svg" alt="" height="22" width="14" />
        </div>
        <img
          class="h-full w-full rounded-t-[20px] object-cover ml:absolute ml:bottom-0 ml:left-0 ml:right-0 ml:top-0 ml:rounded-r-[20px] ml:rounded-t-[0]"
          ngSrc="assets/images/integrate-img-2.jpg"
          alt=""
          height="669"
          width="812" />
      </div>
    </div>
  </div>
</section>
