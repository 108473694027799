<section class="bg-default pb-[60px] lg:pb-[100px]">
  <div class="container text-center">
    <h2>
      {{ 'home.our_advantages.title_text_light' | translate }}
      <span class="font-bold">{{ 'home.our_advantages.title_text_bold' | translate }}</span>
    </h2>
  </div>
  <div class="advantages-list container mt-[25px] grid gap-[24px] md:grid-cols-2 md:gap-[30px] lg:mt-[50px] xl:grid-cols-3 2xl:grid-cols-5">
    <div class="advantage-item" *ngFor="let item of advantages">
      <div class="advantage-item__head">
        <div>
          <img [ngSrc]="item.icon" alt="" height="50" width="52" />
        </div>
      </div>
      <div class="advantage-item__body">
        <h4>
          {{ 'home.our_advantages.' + item.title | translate }}
        </h4>
        <p>
          {{ 'home.our_advantages.' + item.text | translate }}
        </p>
      </div>
    </div>
  </div>
</section>
