import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalDeployComponent } from './components/dialog/modal-deploy/modal-deploy.component';
import { MatDialogRef } from '@angular/material/dialog';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
  },
];
@NgModule({
  declarations: [LayoutComponent, HeaderComponent, FooterComponent],
  imports: [CommonModule, ModalDeployComponent, RouterModule.forChild(routes), MatSelectModule, ReactiveFormsModule, FormsModule, TranslateModule, NgOptimizedImage],
  exports: [LayoutComponent],
	providers: [
    {
        provide: MatDialogRef,
        useValue: {}
    },
	]

})
export class LayoutModule {}
