<section class="bg-default">
  <div class="container">
    <div
      class="flex flex-col items-center justify-between gap-[25px] rounded-[20px] bg-main-purple-500 bg-intro-image bg-cover bg-center bg-no-repeat p-[12px] 2xs:p-[28px] md:p-[60px] xl:flex-row xl:items-start xl:px-[88px] xl:py-[95px] 2xl:flex-row 2xl:gap-[146px]">
      <div class="text-center xl:w-full 2xl:text-left order-2 xl:order-none">
        <h1 class="!md:text-[36px] !lg:text-[42px] !text-[36px] lg:text-left xl:!text-[52px]">
          {{ 'home.exchange_section.title_text_bold' | translate }} <span class="font-light">{{ 'home.exchange_section.title_text_light' | translate }}</span>
        </h1>
        <div
          class="relative mt-[18px] pt-[12px] content-none before:absolute before:left-[70px] before:top-0 before:hidden before:border-b-[14px] before:border-l-[12px] before:border-r-[12px] before:border-main-purple-700 before:border-l-transparent before:border-r-transparent lg:before:flex">
          <div class="rounded-[10px] bg-main-purple-700 p-[25px] text-[16px] text-white text-opacity-70 lg:px-[44px] lg:py-[22px] lg:text-left lg:text-[21px]">
            {{ 'home.exchange_section.text' | translate }}
          </div>
        </div>
      </div>
      <div class="w-full xl:max-w-[400px] 2xl:max-w-[520px] order-1 xl:order-none">
        <exchange-block></exchange-block>
      </div>
    </div>
  </div>
</section>
