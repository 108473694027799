import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformNetworkToDefaultType',
  standalone: true,
})
export class TransformNetworkToDefaultTypePipe implements PipeTransform {
  transform(network: string, currency: string): string {
    if (currency !== network && currency !== 'BNB') {
      switch (network) {
        case 'TRX':
          return 'TRC-20';
        case 'ETH':
          return 'ERC-20';
        case 'BSC':
          return 'BEP-20';
        default:
          return network;
      }
    } else {
      return network;
    }
  }
}
