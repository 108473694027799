import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, debounceTime, map, takeUntil } from 'rxjs';
import { City, CommissionData, CommissionDataCommon, CurrentRate, DirectionData, DirectionsMerchantData, RateCommon } from 'src/app/api/fast-exchange/fast-exchange.model';
import { FastExchangeService } from 'src/app/api/fast-exchange/fast-exchange.service';
import { STORAGE_FE_CREATING_REQUEST } from 'src/app/core/constants/storage.constant';
import { InputParams, TypeCommission, TypeRate } from 'src/app/core/enums/fast-exchange.enum';
import { ParamsFEDirection, ParamsFELoadCity, ParamsFERate } from 'src/app/core/models/fast-exchange';
import { UtilsTransformDirections } from 'src/app/core/utils/transform-fe-directions.utils';
import { FastExchangeActions, FastExchangeSelectors, FastExchangeStoreState } from 'src/app/store/fast-exchange';

@Component({
  selector: 'exchange-block',
  templateUrl: './exchange-block.component.html',
  styleUrls: ['./exchange-block.component.scss'],
})
export class ExchangeBlockComponent implements OnInit, OnDestroy {
  form: FormGroup<any> = this.initForm();

  isOpenDetails = false;
  isDirectionLoaded = false;
  isRateLoaded = false;
  submitted = false;
  reverseValue = false;
  commissionLoader = false;
  firstGetCommission = true;
  commissionLoading = true;
  eventNoneTypeRate = false;
  isSaveCommission = false;
  changeDirection = false;
  loadingButton = false;

  TypeRate = TypeRate;

  cityList: City[] = [];
  selectedCity: City | null = null;

  inputParamsList: InputParams[] = [];

  fromDirections: any[] = [];
  toDirections: any[] = [];

  selectedFromDirection: any = null;
  selectedToDirection: any = null;

  currentRate: CurrentRate | null = null;
  rateCommon: RateCommon | null = null;
  commissionData: CommissionData | null = null;
  commissionDataCommon: CommissionDataCommon | null = null;

  private dot = 'N/A';

  private currentRate$: Subscription = new Subscription;

  isEnabledDirections$: Observable<boolean> = this.store.select(FastExchangeSelectors.selectIsDirectionPresent);
  isLoadedDirections$: Observable<boolean> = this.store.select(FastExchangeSelectors.selectIsLoadingDirection);
  directionsData$: Observable<DirectionData[]> = this.store.select(FastExchangeSelectors.selectDirections);
  fromApiCodeCurrency$: BehaviorSubject<string> = new BehaviorSubject('');
  toApiCodeCurrency$: BehaviorSubject<string> = new BehaviorSubject('');

  isReversed$ = combineLatest([this.directionsData$, this.fromApiCodeCurrency$, this.toApiCodeCurrency$])
  .pipe(
    map(([directionsData, fromCurrencyApiCode, toCurrencyApiCode]) => {
      if (!directionsData || !fromCurrencyApiCode || !toCurrencyApiCode) return false;
      const from_api_code = fromCurrencyApiCode;
      const to_api_code = toCurrencyApiCode;
      let isReverseFromDirection = false;
      let isReverseToDirection = false;
      const fromDirection = this.fromDirections.find((element: DirectionData | any) => {
        const isApiCode = element.currencyDirections.find((item: any) => item.selectData.apiCode === to_api_code);
        if (isApiCode) {
          isReverseFromDirection = true;
          return isApiCode;
        }
      });
      fromDirection?.currencyDirections.find((element: DirectionData | any) => {
        element.exchange_direction_data.find((item: any) => {
          const isApiCode = item.currencyDirections.find((subItem: any) => subItem.selectData.apiCode === from_api_code);
          if (isApiCode) {
            isReverseToDirection = true;
            return isApiCode;
          }
        });
      });
      return Boolean(isReverseFromDirection && isReverseToDirection);
    })
  );
  
  private subjectListenerToAmount = new Subject<string>();
  private unsubscribe$ = new Subject<boolean>();
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<{ fastExchange: FastExchangeStoreState }>,
    private fastExchangeService: FastExchangeService
  ) {
    this.getDirectionsData();
  }

  ngOnInit(): void {
    this.store.dispatch(FastExchangeActions.getDirectionIsPresent());

    this.subscribeChangeRate();
    this.subscribeChangeCommission();

    this.subscribeChangesFromDirection();
    this.subscribeChangesToDirection();
    this.subscribeChangesTypeRate();
    this.subscribeChangesToAmount();
    this.subscribeChangesTypeCommission();
  }

  ngOnDestroy(): void {
    this.store.dispatch(FastExchangeActions.getCommissionDataResetData());
    this.store.dispatch(FastExchangeActions.directionsDataReset());
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  exchangeSelectOutput(value: any, select: string): void {
    switch (select) {
      case 'fromDirection':
        this.form.controls['fromCurrency'].setValue(value);
        break;
      case 'toDirection':
        this.form.controls['toCurrency'].setValue(value);
        break;
    }
  }

  subscribeChangesToAmount(): void {
    this.subjectListenerToAmount.pipe(debounceTime(50), takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        if (this.form.controls['toAmount'].valid) {
          this.isSaveCommission = true;
          this.reverseValue = true;
          this.form.controls['isReverse'].setValue(this.reverseValue);
          this.checkCommission(+(this.form.controls['toAmount'].value), this.reverseValue, this.currentRate?.rate_key);
        } else {
          this.form.controls['fromAmount'].setValue(String(this.dot), { emitEvent: false });
        }
      }
    });
  }

  changesFromAmount(): void {
    if (this.form.controls['fromAmount'].valid) {
      this.isSaveCommission = true;
      this.reverseValue = false;
      this.form.controls['isReverse'].setValue(this.reverseValue);
      this.checkCommission(+(this.form.controls['fromAmount'].value), this.reverseValue, this.currentRate?.rate_key);
    } else {
      this.form.controls['toAmount'].setValue(String(this.dot), { emitEvent: false });
    }
  }

  changesToAmount(): void {
    this.subjectListenerToAmount.next(this.form.controls['toAmount'].value);
  }

  changeTypeRate(typeFixed: TypeRate, disabled: boolean): void {
    !disabled && this.form.controls['typeRate'].setValue(typeFixed);
  }

  setCommonSum(): void {
    if (this.selectedToDirection) {
      this.isSaveCommission = true;
      this.form.controls['fromAmount'].setValue(String(this.selectedToDirection.common_amount));
      this.changesFromAmount();
    }
  }

  setMaxSum(): void {
    if (this.selectedToDirection) {
      this.isSaveCommission = true;
      this.form.controls['fromAmount'].setValue(String(this.selectedToDirection.max_amount));
      this.changesFromAmount();
    }
  }

  reverseExchange(): void {
    this.commissionLoading = true;
    const fromApiCode = this.selectedToDirection.to_merchant_currency.api_code;
    const toApiCode = this.selectedToDirection.from_merchant_currency.api_code;

    let fromDirection: any = null;
    this.fromDirections.forEach((element: DirectionData | any) => {
      element.currencyDirections.forEach((item: any) => {
        if (item.merchant_currency.api_code === fromApiCode) {
          fromDirection = element;
          fromDirection.selectData.merchant = item.selectData.merchant;
          fromDirection.selectData.apiCode = item.selectData.apiCode;
        }
      });
    });
    if (fromDirection) {
      let toDirection: any = null;
      fromDirection.currencyDirections.forEach((element: any) => {
        element.exchange_direction_data.forEach((item: any) => {
          item.currencyDirections.forEach((direction: any) => {
            if (direction.selectData.apiCode === toApiCode && direction.from_merchant_currency.api_code === fromApiCode) {
              toDirection = direction;
              toDirection.selectData.merchant = direction.selectData.merchant;
              toDirection.selectData.apiCode = direction.selectData.apiCode;
            }
          });
        });
      });
      if (toDirection) {
        this.selectedFromDirection = fromDirection;
        this.form.controls['fromCurrency'].setValue(fromDirection, { emitEvent: false});
        this.fromApiCodeCurrency$.next(this.selectedFromDirection.selectData.apiCode);
        this.reverseValue = false;
        const setToDirections = fromDirection.currencyDirections.find((item: any) => item.selectData.apiCode === fromDirection.selectData.apiCode);
        this.toDirections = setToDirections.exchange_direction_data;
        this.form.controls['toCurrency'].setValue(toDirection);
        this.toApiCodeCurrency$.next(toDirection.selectData.apiCode);
      }
    }
  }

  goToExchange(): void {
    console.log(this.form);
    if (this.form.valid) {
      this.submitted = false;
      localStorage.setItem(STORAGE_FE_CREATING_REQUEST, JSON.stringify(this.form.value));
      this.router.navigate(['/exchange']);
    } else {
      this.submitted = true;
    }
  }

  private getDirectionsData() {
    this.store.dispatch(FastExchangeActions.getDirectionsData({ payload: {} }));
    this.store.select(FastExchangeSelectors.selectDirections).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (list: (DirectionData)[]) => {
        if (list && list.length > 0) {
          this.fromDirections = list;
          this.setStartDirection();
        }
      }
    });
  }

  private setStartDirection(): void {
    this.selectedFromDirection = this.fromDirections[0];
    this.form.controls['fromCurrency'].setValue(this.selectedFromDirection, { emitEvent: false });
    this.fromApiCodeCurrency$.next(this.selectedFromDirection.currencyDirections[0].merchant_currency.api_code);
    this.toDirections = this.fromDirections[0].currencyDirections[0].exchange_direction_data;
    this.selectedToDirection = this.toDirections[0].currencyDirections[0];
    this.form.controls['toCurrency'].setValue(this.selectedToDirection, { emitEvent: false });
    this.toApiCodeCurrency$.next(this.selectedToDirection.to_merchant_currency.api_code);
    if (this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') {
      const formCityParams: ParamsFELoadCity = {
        from_code: this.selectedToDirection.from_merchant_currency.api_code,
        to_code: this.selectedToDirection.to_merchant_currency.api_code
      };
      this.fastExchangeService.getSupportedCities(formCityParams).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (list: City[]) => {
          if (list && list.length > 0) {
            this.cityList = [];
            list.forEach((item: City) => {
              this.cityList.push({ ...item, id: item.id });
            });
            this.selectedCity = this.cityList[0];
            this.onChangesCity(this.selectedCity, true);
            const formDirection: ParamsFEDirection = {
              exchange_direction_id: this.selectedToDirection.selectData.id,
              city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
            };
            this.getStartDirectionData(formDirection);
          }
        }
      });
    } else {
      const formDirection: ParamsFEDirection = {
        exchange_direction_id: this.selectedToDirection.selectData.id,
        city_code: ''
      };
      this.getStartDirectionData(formDirection);
    }
  }
  
  private getStartDirectionData(paramsDirection: ParamsFEDirection): void {
    this.isDirectionLoaded = false;
    this.isRateLoaded = false;
    this.fastExchangeService.getDirection(paramsDirection).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (data: DirectionsMerchantData) => {
        this.selectedToDirection = UtilsTransformDirections.convertToMerchantForSelect(data);
        this.setValidateFromToAmount();
        this.setStartValues();
        this.isDirectionLoaded = true;
        const form: ParamsFERate = {
          id: this.selectedToDirection.selectData.id,
          city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
        };
        this.store.dispatch(FastExchangeActions.getCurrentRate({ payload: form }));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private setStartValues(): void {
    this.setValidateForTypeCommission();
    this.form.controls['fromAmount'].setValue(String(this.selectedToDirection.common_amount), {emitEvent: false});
  }
  
  private onChangesCity(city: City | null, silent = false): void {
    this.form.controls['city'].patchValue(city);
    if (!silent) {
      localStorage.setItem(STORAGE_FE_CREATING_REQUEST, JSON.stringify(this.form.value));
      this.submitted = false;
      this.isRateLoaded = false;
      this.isDirectionLoaded = false;
      const formDirection: ParamsFEDirection = {
        exchange_direction_id: this.selectedToDirection.selectData.id,
        city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
      };
      this.fastExchangeService.getDirection(formDirection).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (data: DirectionsMerchantData) => {
          this.selectedToDirection = UtilsTransformDirections.convertToMerchantForSelect(data);
          this.setValidateFromToAmount();
          this.form.controls['toAmount'].setValidators([Validators.max(this.selectedToDirection.reserved_balance), Validators.required]);
          this.form.controls['fromAmount'].setValue(String(this.selectedToDirection.common_amount), { emitEvent: false });
          this.isDirectionLoaded = true;
          this.isRateLoaded = false;
          const form: ParamsFERate = {
            id: this.selectedToDirection.selectData.id,
            city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
          };
          this.store.dispatch(FastExchangeActions.getCurrentRate({ payload: form }));
        },
        error: (error: any) => {
          console.log(error);
        }
      });
    }
  }
  
  private subscribeChangesFromDirection(): void {
    this.form.controls['fromCurrency'].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: (DirectionData)) => {
        this.selectedFromDirection = value;
        this.changeFromDirection(this.selectedFromDirection.exchange_direction_data);
        this.fromApiCodeCurrency$.next(value.merchant_currency.api_code);
      }
    });
  }

  private changeFromDirection(list: DirectionsMerchantData[]): void {
    this.toDirections = list;
    this.selectedToDirection = this.toDirections[0].currencyDirections[0];
    this.form.controls['toCurrency'].setValue(this.selectedToDirection);
  }

  private subscribeChangesToDirection(): void {
    this.form.controls['toCurrency'].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: DirectionsMerchantData) => {
        this.form.controls['typeCommission'].setValue(null);
        this.selectedCity = null;
        this.onChangesCity(this.selectedCity, true);
        this.selectedToDirection = value;
        this.changeToDirection();
        this.toApiCodeCurrency$.next(this.selectedToDirection.to_merchant_currency.api_code);
      }
    });
  }

  private changeToDirection(): void {
    this.form.controls['city'].clearValidators();
    this.form.controls['city'].reset();
    if (this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') {
      const formCityParams: ParamsFELoadCity = {
        from_code: this.selectedToDirection.from_merchant_currency.api_code,
        to_code: this.selectedToDirection.to_merchant_currency.api_code
      };
      this.fastExchangeService.getSupportedCities(formCityParams).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (list: City[]) => {
          if (list && list.length > 0) {
            this.cityList = [];
            list.forEach((item: City) => {
              this.cityList.push({ ...item, id: item.id });
            });
            this.selectedCity = this.cityList[0];
            this.onChangesCity(this.selectedCity, true);
            const formDirection: ParamsFEDirection = {
              exchange_direction_id: this.selectedToDirection.selectData.id,
              city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
            };
            this.getDirectionData(formDirection);
          }
        }
      });
    } else {
      const formDirection: ParamsFEDirection = {
        exchange_direction_id: this.selectedToDirection.selectData.id,
        city_code: ''
      };
      this.getDirectionData(formDirection);
    }
  }
  
  private getDirectionData(paramsDirection: ParamsFEDirection): void {
    this.isDirectionLoaded = false;
    this.isRateLoaded = false;
    this.fastExchangeService.getDirection(paramsDirection).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (data: DirectionsMerchantData) => {
        this.selectedToDirection = UtilsTransformDirections.convertToMerchantForSelect(data);
        this.setValidateFromToAmount();
        this.form.controls['toAmount'].setValidators([Validators.max(this.selectedToDirection.reserved_balance), Validators.required]);
        this.form.controls['fromAmount'].setValue(String(this.selectedToDirection.common_amount), {emitEvent: false});
        this.isDirectionLoaded = true;
        const form: ParamsFERate = {
          id: this.selectedToDirection.selectData.id,
          city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
        };
        this.store.dispatch(FastExchangeActions.getCurrentRate({ payload: form }));
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private subscribeChangesTypeRate(): void {
    this.form.controls['typeRate'].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: TypeRate) => {
        if (this.rateCommon) {
          if (value === TypeRate.FIXED) {
            this.currentRate = this.rateCommon.FIXED;
          } else {
            if (this.rateCommon.FLOAT) {
              this.currentRate = this.rateCommon.FLOAT;
            } else {
              this.currentRate = this.rateCommon.FIXED;
              this.form.controls['typeRate'].setValue(TypeRate.FIXED, { emitEvent: false });
            }
          }
        }
        this.isSaveCommission = true;
        const valueExchange = this.reverseValue ? this.form.controls['toAmount'].value : this.form.controls['fromAmount'].value;
        this.checkCommission(+valueExchange, this.reverseValue, this.currentRate?.rate_key);
      }
    });
  }

  private subscribeChangeRate(): void {
    this.currentRate$ && this.currentRate$.unsubscribe();
    this.currentRate$ = this.store.select(FastExchangeSelectors.selectCurrentRate).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (rate: RateCommon | null) => {
        if (rate) {
          const value = this.reverseValue ? this.form.controls['toAmount'].value : this.form.controls['fromAmount'].value;
          this.rateCommon = rate;
          this.currentRate = (this.form.value.typeRate === TypeRate.FIXED) ? (rate.FIXED || rate.FLOAT) : (rate.FLOAT || rate.FIXED);
          if (this.selectedToDirection && this.selectedToDirection.from_merchant_currency.currency.symbol === this.selectedToDirection?.to_merchant_currency.currency.symbol) {
            this.currentRate = rate.FIXED;
            this.form.controls['typeRate'].setValue(TypeRate.FIXED, { emitEvent: false });
          } else {
            if (this.form.value.typeRate === TypeRate.FIXED) {
              this.currentRate = rate.FIXED;
            } else {
              if (rate.FLOAT) {
                this.currentRate = rate.FLOAT;
              } else {
                this.currentRate = rate.FIXED;
                this.form.controls['typeRate'].setValue(TypeRate.FIXED, { emitEvent: false });
              }
            }
          }
          localStorage.setItem(STORAGE_FE_CREATING_REQUEST, JSON.stringify(this.form.value));
          this.isRateLoaded = true;
          this.checkCommission(+value, this.reverseValue, this.currentRate.rate_key);
        }
      }
    });
  }
  
  private subscribeChangeCommission(): void {
    this.store.select(FastExchangeSelectors.selectCommissionData).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (data: CommissionDataCommon) => {
        setTimeout(() => {
          if (data && (this.selectedToDirection.reserved_balance || this.selectedToDirection.reserved_balance === 0) && this.selectedToDirection.min_amount) {
            this.commissionDataCommon = data;
            const currentCommission = this.chooseTypeCommission(this.form.getRawValue().typeCommission);
            if (this.isSaveCommission && this.form.getRawValue().typeCommission) {
              this.form.controls['typeCommission'].setValue(currentCommission.commission_type, { emitEvent: false });
              this.isSaveCommission = false;
            } else {
              this.form.controls['typeCommission'].setValue(null);
            }
            this.setValidateForTypeCommission();
            if (!this.reverseValue) {
              if (currentCommission.converted_amount_without_commission > this.selectedToDirection.reserved_balance) {
                this.form.controls['toAmount'].setValue(String(this.dot), { emitEvent: false});
                this.form.controls['toAmount'].setErrors({maxLimit: true});
              } else {
                this.form.controls['toAmount'].setValue(String(currentCommission.converted_amount_without_commission), { emitEvent: false});
              }
            } else {
              if (currentCommission.amount < this.selectedToDirection.min_amount) {
                this.form.controls['fromAmount'].setValue(String(this.dot), { emitEvent: false});
                this.form.controls['fromAmount'].setErrors({minLimit: true});
              } else {
                this.form.controls['fromAmount'].setValue(String(currentCommission.amount), { emitEvent: false});
              }
            }
            localStorage.setItem(STORAGE_FE_CREATING_REQUEST, JSON.stringify(this.form.value));
            this.commissionData = currentCommission;
            this.commissionLoader = false;
            this.firstGetCommission = false;
          } else {
            this.commissionData = null;
            this.commissionLoader = true;
          }
          this.changeDirection = false;
          this.commissionLoading = false;
          this.loadingButton = false;
          this.eventNoneTypeRate = false;
        }, 150);
      }
    });

    this.store.select(FastExchangeSelectors.selectCommissionError).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (error: HttpErrorResponse) => {
        if (error) {
          this.firstGetCommission = false;
          if (error?.error.code === 4303) {
            this.form.controls['toAmount'].setErrors({commissionError: true});
          }
          if (error?.error.code === 4009) {
            this.isRateLoaded = false;
            const form: ParamsFERate = {
              id: this.selectedToDirection.selectData.id,
              city_code: ((this.selectedToDirection.from_merchant_currency.merchant.sub_merchant === 'CASH' || this.selectedToDirection.to_merchant_currency.merchant.sub_merchant === 'CASH') && this.form.getRawValue()?.city?.code) ? this.form.getRawValue().city.code : null
            };
            this.store.dispatch(FastExchangeActions.getCurrentRate({ payload: form }));
          } else {
            this.commissionData = null;
            this.commissionLoading = false;
            this.commissionLoader = true;
            this.eventNoneTypeRate = false;
            this.form.controls['fromAmount'].setValue(String(this.dot), { emitEvent: false });
            if (this.commissionDataCommon) {
              this.form.controls['typeCommission'].setValue(null);
            }
          }
        }
      }
    });
  }

  private checkCommission(value: number, reverse: boolean, rate?: string) {
    this.reverseValue = reverse;
    if (!this.reverseValue && value === 0) {
      this.form.controls['toAmount'].setValue(String(this.dot), { emitEvent: false });
    }
    if (value > 0 && this.selectedToDirection && this.currentRate) {
      const data = {
        id: this.selectedToDirection.selectData.id,
        amount: String(value || 0),
        isReverseAmount: this.reverseValue,
        rate: rate ? rate : this.currentRate.rate_key,
        city_code: this.form.getRawValue().city?.code ? this.form.getRawValue().city.code : null
      };
      this.commissionLoading = true;
      this.commissionLoader = true;
      this.eventNoneTypeRate = true;
      this.store.dispatch(FastExchangeActions.getCommissionData({ payload: data }));
    }
  }

  private setValidateFromToAmount(): void {
    this.form.controls['fromAmount'].setValidators([
      Validators.min(this.selectedToDirection.common_amount),
      Validators.max(this.selectedToDirection.max_amount),
      Validators.required
    ]);
    this.form.controls['toAmount'].setValidators([Validators.max(this.selectedToDirection.reserved_balance), Validators.required]);
  }

  private setValidateForTypeCommission(): void {
    if (this.selectedToDirection.from_merchant_currency.currency.type === 'CRYPTO' && this.selectedToDirection.to_merchant_currency.currency.type === 'CRYPTO') {
      this.form.controls['typeCommission'].setValidators(Validators.required);
    } else {
      this.form.controls['typeCommission'].clearValidators();
    }
    this.form.controls['typeCommission'].updateValueAndValidity();
  }

  private chooseTypeCommission(type: TypeCommission | null): CommissionData | any {
    if (this.commissionDataCommon) {
      switch (type) {
        case TypeCommission.RECOMMENDED:
          this.commissionData = this.commissionDataCommon.RECOMMENDED;
          break;
        case TypeCommission.STANDARD:
          this.commissionData = this.commissionDataCommon.STANDARD;
          break;
        case TypeCommission.SERVICE:
          this.commissionData = this.commissionDataCommon.SERVICE;
          break;
        default:
          this.commissionData = this.commissionDataCommon.SERVICE || this.commissionDataCommon.STANDARD || this.commissionDataCommon.RECOMMENDED;
        break;
      }
      return this.commissionData;
    }
  }
  
  private subscribeChangesTypeCommission(): void {
    this.form.controls['typeCommission'].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: TypeCommission) => {
        this.commissionData = this.chooseTypeCommission(value);
        if (this.commissionData) {
          this.form.controls['fromAmount'].setValue(String(this.commissionData.amount), { emitEvent: false });
          this.form.controls['toAmount'].setValue(String(this.commissionData.converted_amount_without_commission), { emitEvent: false });
        }
      }
    });
  }

  private initForm(): FormGroup<any> {
    return this.formBuilder.group({
      fromAmount: this.formBuilder.control(10, [Validators.required, Validators.min(10), Validators.max(500)]),
      toAmount: this.formBuilder.control(300, [Validators.required, Validators.max(400)]),
      fromCurrency: this.formBuilder.control(null, Validators.required),
      toCurrency: this.formBuilder.control(null, Validators.required),
      isReverse: this.formBuilder.control(null),
      city: this.formBuilder.control(null),
      typeCommission: this.formBuilder.control(null),
      typeRate: this.formBuilder.control(TypeRate.FLOAT),
    });
  }
}
