import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { STORAGE_DEPLOY } from 'src/app/core/constants/storage.constant';

@Component({
  selector: 'app-modal-deploy',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './modal-deploy.component.html',
  styleUrls: ['./modal-deploy.component.scss']
})
export class ModalDeployComponent implements OnDestroy {
  // Private
  private unsubscribeAll$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ModalDeployComponent>,
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next(true);
    this.unsubscribeAll$.complete();
  }

  close(): void {
    const nowDate = new Date();
    localStorage.setItem(STORAGE_DEPLOY, JSON.stringify(nowDate.setSeconds(nowDate.getSeconds())));
    this.dialogRef.close();
  }
}
