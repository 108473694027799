import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogExchangeApplicationComponent } from '../../exchange-application.component';
import { DialogCancelApplicationComponent } from '../../../cancel-application/cancel-application.component';
import { FERequest } from 'src/app/api/fast-exchange/fast-exchange.model';
import { ClipboardModule } from 'ngx-clipboard';
import { FastExchangeActions, FastExchangeSelectors, FastExchangeStoreState } from 'src/app/store/fast-exchange';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, takeUntil, timer } from 'rxjs';
import { FastExchangeService } from 'src/app/api/fast-exchange/fast-exchange.service';
import { TransformNetworkToDefaultTypePipe } from 'src/app/shared/pipes/transform-network-to-default-type.pipe';
import { TransformCurrencyWithNetworkToTokenPipe } from 'src/app/shared/pipes/transform-currency-with-network-to-token.pipe';
import { maskContactInfoPipe } from 'src/app/shared/pipes/mask-contact-info.pipe';

@Component({
  selector: 'deposit-application',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule,  ClipboardModule, TransformNetworkToDefaultTypePipe, TransformCurrencyWithNetworkToTokenPipe, maskContactInfoPipe],
  templateUrl: './deposit-application.component.html',
  styleUrls: ['./deposit-application.component.scss'],
})
export class DepositApplicationComponent implements OnDestroy {
  @Input() set requestDataInput(data: FERequest | any) {
    this.requestData = data;
  }  

  requestData: FERequest | any;

  hoursStr = '0';
  minutesStr = '0';
  secondsStr = '0';
  loadingConfirm = false;

  isConfirmPayment$: Observable<boolean> = this.store.select(FastExchangeSelectors.selectIsConfirmPayment);
  
  private unsubscribe$ = new Subject<boolean>();
  private intervalSubscription$: Subscription = new Subscription;
  constructor(
    public dialogRef: MatDialogRef<DialogExchangeApplicationComponent>,
    private dialog: MatDialog, 
    private store: Store<{ fastExchange: FastExchangeStoreState }>,
    private fastExchangeService: FastExchangeService,
  ) {
  }

  ngOnInit() {
    this.countdown(this.requestData.expired_at + 1);
    this.isConfirmPayment$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        this.loadingConfirm = false;
      }
    });
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmRequest(): void {
    this.loadingConfirm = true;
    this.store.dispatch(FastExchangeActions.putConfirmPayment({ payload: this.requestData.id }));
  }

  cancelRequest(): void {
    this.dialog.open(DialogCancelApplicationComponent, {
      panelClass: 'modal-default',
      disableClose: true,
    }).afterClosed().subscribe({
      next: (value: boolean) => {
        if (value) {
          this.revokeRequest();
        }
      }
    });
  }

  private revokeRequest(): void {
    this.fastExchangeService.putRevokeRequest(this.requestData.id).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => { 
        console.log('revoke request');
      }, 
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  private countdown(val: number) {
    let counter = val;
    if (counter > 0) {
      this.intervalSubscription$ && this.intervalSubscription$.unsubscribe();
      this.intervalSubscription$ = timer(1000, 1000).pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: () => {
          counter = --counter;
          const hours = Math.floor((counter / 60) / 60);
          const minutes = Math.floor(counter / 60) % 60;
          const seconds = counter % 60;
          this.hoursStr = `${hours > 10 ? `${hours}` : `0${hours}`}`;
          this.minutesStr = `${minutes >= 10 ? `${minutes}` : `0${minutes}`}`;
          this.secondsStr = `${seconds >= 10 ? `${seconds}` : `0${seconds}`}`;
          if (counter === 0) {
            this.revokeRequest();
          }
        }
      });
    } else {
        this.revokeRequest();
    }
  }
}
