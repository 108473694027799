<div class="flex min-w-[80vw] flex-col items-center md:min-w-[566px]">
  <div class="z-[2] mb-[-43px] flex h-[60px] w-[60px] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-800 sm:h-[86px] sm:w-[86px]">
    <span (click)="closeDialog()" class="flex h-[44px] w-[44px] cursor-pointer flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-300 p-[14px] sm:h-[64px] sm:w-[64px] sm:p-0">
      <img ngSrc="assets/images/svg/icons/close-icons.svg" alt="" height="24" width="24" />
    </span>
  </div>
  <div class="flex w-full flex-col items-center gap-8 rounded-[30px] bg-main-dark-blue-300 px-[25px] pb-[25px] pt-[50px] text-center text-[12px] sm:px-[40px] sm:pb-[40px] sm:pt-[65px] sm:text-[16px] lg:gap-[30px]">
    <img class="w-22 h-22" src="assets/icons/failed-icon.svg" alt="failed-icon">
    <p class="text-lg leading-none text-opacity-60 text-white font-medium">{{ 'modals.not_found.subtext' | translate }}</p>
    <p class="text-[32px] text-white font-medium">{{ 'modals.not_found.sorry' | translate }}</p>
  </div>
</div>
