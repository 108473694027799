import { createReducer, Action, on } from '@ngrx/store';
import { FastExchangeStoreState } from './fast-exchange.state';
import { FastExchangeActions } from './fast-exchange.actions';
import { UtilsTransformDirections } from 'src/app/core/utils/transform-fe-directions.utils';

export const userInitialState: FastExchangeStoreState = {
  isLoadingDirection: false,
  isLoadingInfo: false,
  isDirectionPresent: false,
  currentRate: null,
  directions: [],
  isValidateWallet: true,
  commissionData: null,
  commissionError: null,
  responseFERequest: null,
  isConfirmPayment: false,
  depositParams: null,
  responseFailed: false
};

const createFastExchangeReducer = createReducer(
  Object.assign({}, userInitialState),

  on(FastExchangeActions.getSearchInfoRequest, state => ({
    ...state,
    isLoadingInfo: true,
    responseFailed: false
  })),
  on(FastExchangeActions.getSearchInfoRequestSuccess, (state, action) => ({
    ...state,
    isLoadingInfo: false,
    responseFERequest: action.payload,
    responseFailed: false
  })),
  on(FastExchangeActions.getSearchInfoRequestFailure, state => ({
    ...state,
    isLoadingInfo: false,
    responseFERequest: null,
    responseFailed: true
  })),
  on(FastExchangeActions.getSearchInfoRequestReset, state => ({
    ...state,
    isLoadingInfo: false,
    responseFERequest: null,
    responseFailed: false
  })),

  on(FastExchangeActions.getRequestInfo, state => ({
    ...state,
    isLoadingInfo: true,
    responseFailed: false
  })),
  on(FastExchangeActions.getRequestInfoSuccess, (state, action) => ({
    ...state,
    isLoadingInfo: false,
    responseFERequest: action.payload,
    responseFailed: false
  })),
  on(FastExchangeActions.getRequestInfoFailure, state => ({
    ...state,
    isLoadingInfo: false,
    responseFERequest: null,
    responseFailed: true
  })),

  on(FastExchangeActions.getDirectionIsPresent, state => ({
    ...state,
    isDirectionPresent: false,
  })),
  on(FastExchangeActions.getDirectionIsPresentSuccess, (state, action) => ({
    ...state,
    isDirectionPresent: action.payload,
  })),
  on(FastExchangeActions.getDirectionIsPresentFailure, state => ({
    ...state,
    isDirectionPresent: false,
  })),

  on(FastExchangeActions.getDirectionsData, state => ({
    ...state,
    isLoadingDirection: false,
    directions: [],
  })),
  on(FastExchangeActions.getDirectionsDataSuccess, (state, action) => ({
    ...state,
    isLoadingDirection: true,
    directions: UtilsTransformDirections.tranformDirectionData(action.payload),
  })),
  on(FastExchangeActions.getDirectionsDataFailure, state => ({
    ...state,
    isLoadingDirection: false,
    directions: [],
  })),
  on(FastExchangeActions.directionsDataReset, state => ({
    ...state,
    isLoadingDirection: false,
    directions: [],
  })),

  on(FastExchangeActions.getCurrentRate, state => ({
    ...state,
  })),
  on(FastExchangeActions.getCurrentRateSuccess, (state, action) => ({
    ...state,
    currentRate: action.payload,
  })),
  on(FastExchangeActions.getCurrentRateFailure, state => ({
    ...state,
    currentRate: null,
  })),

  on(FastExchangeActions.getDepositParams, state => ({
    ...state,
    isLoadingInfo: true,
    depositParams: null,
  })),
  on(FastExchangeActions.getDepositParamsSuccess, (state, action) => ({
    ...state,
    isLoadingInfo: false,
    depositParams: action.payload,
  })),
  on(FastExchangeActions.getDepositParamsFailure, state => ({
    ...state,
    isLoadingInfo: false,
    depositParams: null,
  })),

  on(FastExchangeActions.getValidateAddress, state => ({
    ...state,
  })),
  on(FastExchangeActions.getValidateAddressSuccess, (state, action) => ({
    ...state,
    isValidateWallet: action.payload,
  })),
  on(FastExchangeActions.getValidateAddressFailure, state => ({
    ...state,
    isValidateWallet: null,
  })),
  on(FastExchangeActions.getValidateAddressReset, state => ({
    ...state,
    isValidateWallet: null,
  })),

  on(FastExchangeActions.getCommissionData, state => ({
    ...state,
    commissionError: null,
  })),
  on(FastExchangeActions.getCommissionDataSuccess, (state, action) => ({
    ...state,
    commissionData: action.payload,
    commissionError: null,
  })),
  on(FastExchangeActions.getCommissionDataFailure, (state, action) => ({
    ...state,
    commissionData: null,
    commissionError: action.error,
  })),
  on(FastExchangeActions.getCommissionDataResetData, state => ({
    ...state,
    commissionData: null,
    commissionError: null,
  })),

  on(FastExchangeActions.putConfirmPayment, state => ({
    ...state,
    isConfirmPayment: false,
  })),
  on(FastExchangeActions.putConfirmPaymentSuccess, (state, action) => ({
    ...state,
    isConfirmPayment: action.payload,
  })),
  on(FastExchangeActions.putConfirmPaymentFailure, state => ({
    ...state,
    isConfirmPayment: false,
  })),

  on(FastExchangeActions.cleanStore, state => ({
    ...state,
    isLoadingDirection: false,
    isLoadingInfo: false,
    isDirectionPresent: false,
    currentRate: null,
    directions: [],
    isValidateWallet: null,
    commissionData: null,
    responseFERequest: null,
    isConfirmPayment: false,
    depositParams: null,
    responseFailed: false
  })),
);

export function fastExchangeReducer(state: any, action: Action) {
  return createFastExchangeReducer(state, action);
}
