import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './core/layout/layout.module';
import { HomeModule } from './modules/pages/home/home.module';
import { FastExchangeEffects, FastExchangeStoreModule, fastExchangeReducer } from './store/fast-exchange';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgOptimizedImage } from '@angular/common';
import { BecomePartnerComponent } from './shared/components/become-partner/become-partner.component';
import { MatDialogModule } from '@angular/material/dialog';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { ClipboardAlertComponent } from './shared/components/clipboard-alert/clipboard-alert.component';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';

export function loaderJsonTranslate(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18/', '.json');
}

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

const maskConfig: Partial<IConfig> = {
  validation: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    ClipboardAlertComponent,
    HomeModule,
    BecomePartnerComponent,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    RouterModule,
    MatDialogModule,
    StoreModule.forRoot([fastExchangeReducer]),
    EffectsModule.forRoot([FastExchangeEffects]),
    HttpClientModule,
    FastExchangeStoreModule,
    // NgRx
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: loaderJsonTranslate,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    NgOptimizedImage,
    BecomePartnerComponent,
  ],
  providers: [
    provideEnvironmentNgxMask(maskConfig),
    { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
