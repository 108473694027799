import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogExchangeApplicationComponent } from '../../exchange-application.component';
import { Router } from '@angular/router';

@Component({
  selector: 'not-found-application',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule],
  templateUrl: './not-found-application.component.html',
  styleUrls: ['./not-found-application.component.scss']
})
export class NotFoundApplicationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogExchangeApplicationComponent>,
    private router: Router,
  ) {
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
}
