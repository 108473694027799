import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { DepositApplicationComponent } from './components/deposit-application/deposit-application.component';
import { StatusApplicationComponent } from './components/status-application/status-application.component';
import { NotFoundApplicationComponent } from './components/not-found-application/not-found-application.component';
import { Store } from '@ngrx/store';
import { FastExchangeActions, FastExchangeSelectors, FastExchangeStoreState } from 'src/app/store/fast-exchange';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { FERequest } from 'src/app/api/fast-exchange/fast-exchange.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SocketClientService, SocketClientState } from 'src/app/core/services/socket.service';
import { LoaderComponent } from '../../loader/loader.component';
import { MatDialogRef } from '@angular/material/dialog';

export enum ExchangeApplication  {
  cancel = 'cancel',
  deposit = 'deposit',
  status = 'status',
  loader = 'loader'
}

@Component({
  selector: 'app-exchange-application',
  standalone: true,
  imports: [DepositApplicationComponent, StatusApplicationComponent, NotFoundApplicationComponent, CommonModule, LoaderComponent, NgOptimizedImage],
  templateUrl: './exchange-application.component.html',
  styleUrls: ['./exchange-application.component.scss'],
})
export class DialogExchangeApplicationComponent implements OnDestroy {
  ExchangeApplication = ExchangeApplication;
  currentExchangeApplication: ExchangeApplication | null = null;
  requestData: FERequest | any = null;

  private unsubscribe$ = new Subject<boolean>();
  private depositStatus$: Subscription = new Subscription;
  private withdrawStatus$: Subscription = new Subscription;
  private changeRequest$: Subscription = new Subscription;
  constructor(
    private store: Store<{ fastExchange: FastExchangeStoreState }>,
    private activatedRoute: ActivatedRoute,
    private socketClient: SocketClientService,
    public dialogRef: MatDialogRef<DialogExchangeApplicationComponent>,
    private router: Router,
  ) {
    this.subscribeParams();
    this.subscribeFEResponse();
    
    this.socketClient.state.pipe(filter(state => state === SocketClientState.ERROR), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.subscribeSocketStatus(this.requestData);
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.depositStatus$ && this.depositStatus$.unsubscribe();
    this.withdrawStatus$ && this.withdrawStatus$.unsubscribe();
    this.changeRequest$ && this.changeRequest$.unsubscribe();
    this.store.dispatch(FastExchangeActions.getSearchInfoRequestReset());
  }

  closeDialog(): void {
    this.dialogRef.close();
    if (this.requestData.status === 'AUTO_REVOKED' || this.requestData.status === 'REVOKED_BY_ADMIN' || this.requestData.status === 'REVOKED_BY_ACCOUNT' || this.requestData.status === 'EXPIRED' || (this.requestData.status === 'COMPLETED')) {
      this.router.navigate(['/']);
    }
  }

  private subscribeParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (params: Params) => {
        if (params['request']) {
          this.store.dispatch(FastExchangeActions.getRequestInfo({ payload: params['request'] }));
        }
        if (params['searchRequest']) {
          this.store.dispatch(FastExchangeActions.getSearchInfoRequest({ payload: params['searchRequest'] }));
        }
      },
    });
  }

  private subscribeFEResponse(): void {
    this.store.select(FastExchangeSelectors.selectFEResponse).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (request: FERequest | any) => {
        if (request) {
          this.currentExchangeApplication = ExchangeApplication.deposit;
          this.requestData = request;
          this.subscribeSocketStatus(this.requestData);
          this.changeStatus();
        } else {
          this.currentExchangeApplication = ExchangeApplication.loader;
        }
      }
    });
    
    this.store.select(FastExchangeSelectors.selectFEFailed).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (request: boolean) => {
        if (request) {
          this.currentExchangeApplication = ExchangeApplication.cancel;
        }
      }
    });
  }

  private changeStatus(): void {
    if (this.requestData.status === 'AUTO_REVOKED' || this.requestData.status === 'REVOKED_BY_ADMIN' || this.requestData.status === 'REVOKED_BY_ACCOUNT'
      || this.requestData.withdraw_request_status === 'AUTO_REVOKED' || this.requestData.withdraw_request_status === 'REVOKED_BY_ADMIN' || this.requestData.withdraw_request_status === 'REVOKED_BY_ACCOUNT'  || this.requestData.withdraw_request_status === 'COMPLETED'
      || this.requestData.deposit_request_status === 'AUTO_REVOKED' || this.requestData.deposit_request_status === 'REVOKED_BY_ADMIN' || this.requestData.deposit_request_status === 'REVOKED_BY_ACCOUNT' || this.requestData.deposit_request_status === 'EXPIRED' || this.requestData.deposit_request_status === 'PAID_UNCONFIRMED'
      || this.requestData.deposit_request_status === 'COMPLETED'
      ) {
        this.currentExchangeApplication = ExchangeApplication.status;
      }

  }
  
  private subscribeSocketStatus(data: FERequest): void {
    this.depositStatus$ && this.depositStatus$.unsubscribe();
    this.depositStatus$ = this.socketClient.onPlainMessage(`/topic/depositStatus.${data.deposit_request_id}`)
      .pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (response: string) => {
          console.log(`DEPOSITE SOCKET STATUS ${response}`);
          this.requestData.deposit_request_status = response;
          this.changeStatus();
        }
      });
    this.socketClient.send(`/app/depositStatus.${data.deposit_request_id}`);
    this.withdrawStatus$ && this.withdrawStatus$.unsubscribe();
    this.withdrawStatus$ = this.socketClient.onPlainMessage(`/topic/withdrawStatus.${data.withdraw_request_id}`)
      .pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (response: string) => {
          console.log(`WITHDRAW SOCKET STATUS ${response}`);
          this.requestData.withdraw_request_status = response;
          this.changeStatus();
        }
      });
    this.socketClient.send(`/app/withdrawStatus.${data.withdraw_request_id}`);
    this.changeRequest$ && this.changeRequest$.unsubscribe();
    this.changeRequest$ = this.socketClient.onPlainMessage(`/topic/requestInfo.${data.id}}`).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response: string) => {
        this.requestData = JSON.parse(response);
        this.changeStatus();
      }
    });
    this.socketClient.send(`/app/requestInfo.${data.id}}`);
  }
}
