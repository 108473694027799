import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'clipboard-alert',
  standalone: true,
  templateUrl: './clipboard-alert.component.html',
  styleUrls: ['./clipboard-alert.component.scss']
})
export class ClipboardAlertComponent {
  @Output() hideAlert = new EventEmitter<boolean>();
  
  copiedNotif = false;

  closeAlert(): void {
    this.hideAlert.emit(true);
  }
}
