import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformCurrencyWithNetworkToToken',
  standalone: true,
})
export class TransformCurrencyWithNetworkToTokenPipe implements PipeTransform {
  transform(currency: string, network: string, outputDefaultNetwork?: boolean): string {
    if (currency !== network && currency !== 'BNB') {
      switch (network) {
        case 'TRX':
          return currency + ' TRC-20';
        case 'ETH':
          return currency + ' ERC-20';
        case 'BSC':
          return currency + ' BEP-20';
        default:
          return currency + (outputDefaultNetwork ? ' ' + network : '');
      }
    } else {
      if (currency === network) {
        return currency;
      } else {
        return currency + (outputDefaultNetwork ? ' ' + network : '');
      }
    }
  }
}
