<footer class="bg-main-dark-blue pb-[20px] pt-[35px] md:pb-[25px]">
  <div class="container mb-[25px] flex flex-col items-center justify-between gap-[42px] border-b border-solid border-white border-opacity-20 pb-[42px] lg:flex-row lg:gap-[15px]">
    <a routerLink="/">
      <img ngSrc="assets/images/svg/footer-logo.svg" alt="" height="27" width="248" />
    </a>
    <ul class="flex items-center gap-10 2xl:gap-[86px]">
      <li>
        <a routerLink="/" class="text-lg hover:text-main-purple-400">{{ 'footer.nav.home' | translate }}</a>
      </li>
      <li>
        <a routerLink="/faq" class="text-lg hover:text-main-purple-400">{{ 'footer.nav.faq' | translate }}</a>
      </li>
      <li>
        <a routerLink="/contacts" class="text-lg hover:text-main-purple-400">{{ 'footer.nav.contacts' | translate }}</a>
      </li>
    </ul>
    <ul class="socials">
      <li>
        <a target="_blank" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.13055 0.159547C6.01431 0.255032 4.18006 0.772426 2.69894 2.24728C1.21265 3.72989 0.701684 5.5714 0.605961 7.66563C0.546457 8.97274 0.198512 18.8477 1.20748 21.4375C1.88789 23.1846 3.22797 24.5279 4.99108 25.2105C5.81378 25.5305 6.75292 25.7471 8.13055 25.8103C19.6496 26.3316 23.9196 26.0478 25.7202 21.4375C26.0397 20.6168 26.2596 19.6785 26.3204 18.3043C26.8469 6.7557 26.2351 4.25248 24.2275 2.24728C22.6351 0.658872 20.7621 -0.422396 8.13055 0.159547ZM8.2366 23.4877C6.97539 23.431 6.29111 23.2208 5.83449 23.044C4.68581 22.5976 3.82302 21.7383 3.37933 20.5964C2.61096 18.6286 2.8658 9.28265 2.93436 7.77036C3.00163 6.28904 3.30172 4.93522 4.34691 3.89005C5.64046 2.5997 7.31171 1.96731 18.6911 2.48088C20.1761 2.54797 21.533 2.84746 22.5808 3.89005C23.8743 5.18039 24.5159 6.8645 23.9933 18.2002C23.9364 19.4582 23.7256 20.1409 23.5484 20.5964C22.3777 23.5964 19.6845 24.0129 8.2366 23.4877ZM18.8166 6.09649C18.8166 6.9507 19.5112 7.64531 20.3688 7.64531C21.2265 7.64531 21.9224 6.9507 21.9224 6.09649C21.9224 5.24229 21.2265 4.5483 20.3688 4.5483C19.5112 4.5483 18.8166 5.24229 18.8166 6.09649ZM6.82147 12.9843C6.82147 16.6437 9.79535 19.6106 13.4639 19.6106C17.1324 19.6106 20.1062 16.6437 20.1062 12.9843C20.1062 9.3249 17.1324 6.35992 13.4639 6.35992C9.79535 6.35992 6.82147 9.3249 6.82147 12.9843ZM9.15245 12.9843C9.15245 10.6101 11.0824 8.68379 13.4639 8.68379C15.8453 8.68379 17.7753 10.6101 17.7753 12.9843C17.7753 15.3598 15.8453 17.2867 13.4639 17.2867C11.0824 17.2867 9.15245 15.3598 9.15245 12.9843Z"
              fill="" />
          </svg>
        </a>
      </li>
      <li>
        <a target="_blank" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.32129 20V11H10.0537L10.5 7H7.32129V5.05176C7.32129 4.02176 7.3476 3 8.7869 3H10.2447V0.140137C10.2447 0.0971367 8.99249 0 7.72568 0C5.07999 0 3.4234 1.6572 3.4234 4.7002V7H0.5V11H3.4234V20H7.32129Z"
              fill="" />
          </svg>
        </a>
      </li>
      <li>
        <a target="_blank" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.3625 20C17.7962 20 22.9562 12.3044 22.9562 5.63153C22.9562 5.41246 22.9562 5.19505 22.9412 4.97845C23.945 4.26464 24.8113 3.37836 25.5 2.36428C24.565 2.77287 23.5712 3.04082 22.555 3.15896C23.625 2.52762 24.4262 1.53574 24.81 0.365349C23.8025 0.953619 22.7012 1.3688 21.5525 1.59156C19.61 -0.441553 16.3612 -0.540058 14.295 1.37244C12.9637 2.60559 12.3975 4.44421 12.8112 6.19794C8.6875 5.99365 4.845 4.07626 2.24 0.921997C0.87875 3.22954 1.575 6.18044 3.82875 7.6622C3.0125 7.63881 2.21375 7.42244 1.5 7.03108V7.09517C1.50125 9.49873 3.2225 11.5683 5.615 12.0446C4.86 12.2476 4.0675 12.2771 3.3 12.1306C3.97125 14.1884 5.8975 15.5978 8.09125 15.6384C6.275 17.0438 4.03125 17.807 1.72125 17.8045C1.31375 17.8033 0.90625 17.7799 0.5 17.7319C2.84625 19.2137 5.575 20 8.3625 19.9963"
              fill="" />
          </svg>
        </a>
      </li>
    </ul>
  </div>
  <div class="container text-center">
    <a routerLink="/terms-and-conditions" class="text-[16px] text-white text-opacity-70 underline hover:text-opacity-100">
      {{ 'footer.privacy_text' | translate }}
    </a>
  </div>
</footer>
