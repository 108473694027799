import { Pipe, PipeTransform } from '@angular/core';

export const STABLECOIN_LIST = ['USDT', 'USDC', 'BUSD', 'TUSD', 'USDP', 'USDD', 'USDN'];

@Pipe({
  name: 'isExchangeStablecoinToStablecoin',
  standalone: true
})

export class IsExchangeStablecoinToStablecoin implements PipeTransform {
  transform(from: string, to: string): boolean {
    if (from && to) {
      return (STABLECOIN_LIST.includes(from) && STABLECOIN_LIST.includes(to) && (from === to));
    } else {
      return false;
    }
  }
}
