import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import * as SockJS from 'sockjs-client';
import { Client, over, Message } from 'stompjs';

import { environment } from '../../../environments/environment';

export enum SocketClientState {
    ATTEMPTING, CONNECTED, ERROR, RECONNECT
}

@Injectable({
    providedIn: 'root'
})
export class SocketClientService implements OnDestroy {
    state = new BehaviorSubject<SocketClientState>(SocketClientState.ATTEMPTING);

    constructor() {
        this.connectToSocket();
    }

    private client!: Client;

    static jsonHandler(message: Message): any {
        return JSON.parse(message.body);
    }

    static textHandler(message: Message): string {
        return message.body;
    }

    connectToSocket(): void {
        this.client = over(new SockJS(environment.socketUrl));
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.client.debug = () => {};
        const login = environment.socketLogin;
        const pass = environment.socketPass;
        this.client.connect({ login, pass }, () => {
            this.state.next(SocketClientState.CONNECTED);
        },
            (error) => {
                this.ngOnDestroy();
                this.reconnect();
                this.state.next(SocketClientState.ERROR);
            });
    }

    connect(): Observable<Client> {
        return new Observable<Client>(observer => {
            this.state.pipe(filter(state => state === SocketClientState.CONNECTED)).subscribe(() => {
                observer.next(this.client);
            });
        });
    }

    reconnect(): void {
        interval(3000).pipe(first()).subscribe({
            next: () => {
                console.log('Reconnect');
                this.state.next(SocketClientState.ATTEMPTING);
                this.connectToSocket();
                this.state.next(SocketClientState.RECONNECT);
            }
        });
    }

    ngOnDestroy() {
        this.connect().pipe(first()).subscribe((inst: any) => inst.disconnect(null));
    }

    onMessage(url: string, handler = SocketClientService.jsonHandler): Observable<any> {
        return this.connect().pipe(first(), switchMap(inst => {
            return new Observable<any>(observer => {
                const subscription: any = inst.subscribe(url, message => {
                    observer.next(handler(message));
                });
                return () => {
                    return inst.unsubscribe(subscription.id);
                };
            });
        }));
    }

    onPlainMessage(url: string): Observable<string> {
        return this.onMessage(url, SocketClientService.textHandler);
    }

    send(url: string, payload: any = null): void {
        this.connect()
            .pipe(first())
            .subscribe(inst => inst.send(`${url}`, {}, JSON.stringify(payload)));
    }
}
