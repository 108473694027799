<div *ngIf="requestData" class="flex min-w-[80vw] flex-col items-center md:min-w-[566px]">
  <div class="z-[2] mb-[-43px] flex h-[60px] w-[60px] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-800 sm:h-[86px] sm:w-[86px]">
    <span (click)="closeDialog()" class="flex h-[44px] w-[44px] cursor-pointer flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-300 p-[14px] sm:h-[64px] sm:w-[64px] sm:p-0">
      <img ngSrc="assets/images/svg/icons/close-icons.svg" alt="" height="24" width="24" />
    </span>
  </div>
  
  <div class="flex flex-col overflow-y-auto max-h-[90%] rounded-[30px] bg-main-dark-blue-300 px-[25px] pb-[25px] pt-[50px] sm:px-[40px] sm:pb-[40px] sm:pt-[65px] w-full">
    <button type="button" ngxClipboard [cbContent]="requestData.number" class="text-[12px] text-left flex items-center opacity-60 transition-all hover:opacity-100 cursor-pointer mb-4">
      {{ 'shared.request_number' | translate }} {{requestData.number}}
      <img class="ml-2" ngSrc="assets/images/svg/icons/icon-copy.svg" alt="" height="18" width="18" />
    </button>
    <div class="flex flex-col gap-[24px]">
      <div class="bordred-solid flex justify-between gap-[15px] border-b border-white/20 pb-[15px]">
        <div class="flex flex-col w-1/2">
          <span class="text-white/60">{{ 'modals.status.labels.you_give' | translate }}</span>
          <span class="font-semibold">
            {{requestData.from_amount | number:('1.0-8')}} 
            {{requestData.from_currency_symbol | transformCurrencyWithNetworkToToken : requestData.from_merchant_symbol : true}}
          </span>
        </div>
        <div class="flex flex-col w-1/2">
          <span class="text-white/60">{{ 'modals.status.labels.exchange_rate' | translate }}</span>
          <span class="font-semibold">{{requestData.front_rate}}</span>
        </div>
      </div>
      <div class="bordred-solid flex justify-between gap-[15px] border-b border-white/20 pb-[15px]">
        <div class="flex flex-col w-1/2">
          <span class="text-white/60">{{ 'modals.status.labels.receive' | translate }}</span>
          <span class="font-semibold">
            {{requestData.to_amount_without_commission | number:('1.0-8')}} 
            {{requestData.to_currency_symbol | transformCurrencyWithNetworkToToken : requestData.to_merchant_symbol : true}}
          </span>
        </div>

        <div class="flex flex-col w-1/2" *ngIf="requestData.withdraw_params.crypto_address">
          <span class="text-white/60">{{ 'modals.status.labels.on_the_wallet' | translate }}</span>
          <span class="font-semibold break-all">
            {{requestData.withdraw_params.crypto_address}}
            {{requestData.withdraw_params?.crypto_is_need_tag_address ? (' : ' + requestData?.withdraw_params?.crypto_tag_address) : ''}}
          </span>
        </div>

        <div class="flex flex-col w-1/2" *ngIf="requestData.withdraw_params.card_number">
          <span class="text-white/60">{{ 'modals.status.labels.on_the_card' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.card_number}}</span>
        </div>

        <div class="flex flex-col w-1/2" *ngIf="requestData.withdraw_params.fiat_account">
          <span class="text-white/60">{{ 'modals.status.labels.on_the_fiat' | translate }}</span>
          <span class="font-semibold">{{requestData.withdraw_params.fiat_account}}</span>
        </div>

      </div>
      <div class="bordred-solid flex justify-between gap-[15px] border-b border-white/20 pb-[15px]">
        <div class="flex flex-col">
          <span class="text-white/60">Email:</span>
          <span class="font-semibold">{{requestData.withdraw_params.owner_email | maskContactInfo:'email'}}</span>
        </div>
      </div>
    </div>

    <p class="mt-[30px] text-[12px] text-white/60 sm:text-[14px]">
      {{ 'modals.deposit.notifications' | translate: {amount: (requestData.from_amount | number:('1.0-8')), currency: requestData.from_currency_symbol, type: 'wallet'} }}
    </p>
    <div class="mt-[30px] flex flex-col gap-[12px]">
      <button  ngxClipboard [cbContent]="requestData.deposit_params.crypto_address || requestData.deposit_params.card_number || requestData.deposit_params.fiat_account" class="btn btn-black flex min-w-full !flex-row items-center gap-2">
        <span class="break-all">
          <ng-container *ngIf="requestData.deposit_params.crypto_address">{{requestData.deposit_params.crypto_address}}</ng-container>
          <ng-container *ngIf="requestData.deposit_params.card_number" >{{requestData.deposit_params.card_number}}</ng-container>
          <ng-container *ngIf="requestData.deposit_params.fiat_account" >{{requestData.deposit_params.fiat_account}}</ng-container>
        </span>
        <img ngSrc="assets/images/svg/icons/icon-copy.svg" alt="" height="24" width="24" />
      </button>
      <button ngxClipboard [cbContent]="requestData.deposit_params.crypto_is_need_tag_address" *ngIf="requestData.deposit_params.crypto_address && requestData.deposit_params?.crypto_is_need_tag_address" class="btn btn-black flex min-w-full !flex-row items-center gap-2 mt-3">
        <span>{{requestData.deposit_params.crypto_is_need_tag_address}}</span>
        <img ngSrc="assets/images/svg/icons/icon-copy.svg" alt="" height="24" width="24" />
      </button>

      <button [ngClass]="(loadingConfirm) ? 'opacity-50 pointer-events-none' : ''" class="btn btn-primary-light min-w-full" (click)="confirmRequest()">
        <span>{{ 'modals.deposit.button_title.confirm' | translate }}</span>
      </button>
      <span class="text-center">or</span>
      <button (click)="cancelRequest()" class="btn btn-red min-w-full">{{ 'modals.deposit.button_title.cancel' | translate }}</button>
    </div>
    <div class="mt-[40px] flex items-center justify-center gap-[12px]">
      <div class="flex w-full max-w-[86px] flex-col items-center rounded-[10px] border border-solid border-main-purple-400 p-[10px] text-center">
        <span class="mb-[5px] w-full border-b border-solid border-main-gray-300 pb-[5px] text-[30px] font-bold leading-[1] sm:text-[42px]"> {{+hoursStr > 0 ? hoursStr : '00' }} </span>
        <span class="text-[10px] uppercase sm:text-[14px]">{{ 'modals.deposit.timer.hours' | translate }}</span>
      </div>

      <div class="flex w-full max-w-[86px] flex-col items-center rounded-[10px] border border-solid border-main-purple-400 p-[10px] text-center">
        <span class="mb-[5px] w-full border-b border-solid border-main-gray-300 pb-[5px] text-[30px] font-bold leading-[1] sm:text-[42px]"> {{+minutesStr > 0 ? minutesStr : '00'}} </span>
        <span class="text-[10px] uppercase sm:text-[14px]">{{ 'modals.deposit.timer.minutes' | translate }}</span>
      </div>
      <div class="flex w-full max-w-[86px] flex-col items-center rounded-[10px] border border-solid border-main-purple-400 p-[10px] text-center">
        <span class="mb-[5px] w-full border-b border-solid border-main-gray-300 pb-[5px] text-[30px] font-bold leading-[1] sm:text-[42px]"> {{+secondsStr > 0 ? secondsStr : '00'}} </span>
        <span class="text-[10px] uppercase sm:text-[14px]">{{ 'modals.deposit.timer.seconds' | translate }}</span>
      </div>
    </div>
  </div>
</div>
