export const LANG_LIST = [
  {
    id: 1,
    value: 'EN',
    viewValue: 'English',
  },
  {
    id: 2,
    value: 'UA',
    viewValue: 'Ukrainian',
  },
  {
    id: 3,
    value: 'RU',
    viewValue: 'Russian',
  },
];