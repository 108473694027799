import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, interval, takeUntil } from 'rxjs';
import { DialogExchangeApplicationComponent } from './shared/components/dialogs/exchange-application/exchange-application.component';
import { STORAGE_SITE_LANG } from './core/constants/storage.constant';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'Cash Point Plus';
  copiedNotif = false;

  private unsubscribe$ = new Subject<boolean>();
  constructor(
    public translate: TranslateService, 
    private dialog: MatDialog, 
    private activatedRoute: ActivatedRoute,
    private clipboardService: ClipboardService,
    private sso: ScrollStrategyOptions
  ) {
    translate.addLangs(['en', 'ua', 'ru']);
    translate.setDefaultLang('en');
    const lang = localStorage.getItem(STORAGE_SITE_LANG) ? localStorage.getItem(STORAGE_SITE_LANG) : 'EN';
    lang && translate.use(lang.toLowerCase());
    this.listenQueryParams();
    this.subscribeClipboardService();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  listenQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (params: Params) => {
        if (params['request'] || params['searchRequest']) {
          this.openDialogRequest();
        }
      },
    });
  }

  openDialogRequest(): void {
    const scrollStrategy = this.sso.block();
    setTimeout(() => {
      this.dialog.open(DialogExchangeApplicationComponent, {
        panelClass: 'modal-default',
        disableClose: true,
        scrollStrategy: scrollStrategy
      });
    }, 0);
  }

  private subscribeClipboardService(): void {
    this.clipboardService.copyResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (response: IClipboardResponse) => {
        if (response.isSuccess) {
          this.copiedNotif = true;
          interval(5000).pipe(takeUntil(this.unsubscribe$)).subscribe({next: () => {this.copiedNotif = false;}});
        }
      }
    });
  }
}
