import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, filter, takeUntil } from 'rxjs';
import { STORAGE_DEPLOY } from '../constants/storage.constant';
import { SocketClientService, SocketClientState } from '../services/socket.service';
import { ModalDeployComponent } from './components/dialog/modal-deploy/modal-deploy.component';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  // Public
  offNoticeDeploy = false;

  // Private
  private unsubscribeAll$ = new Subject<boolean>();
  private deployDialogRef?: MatDialogRef<any>;
  constructor(
    private dialog: MatDialog,
    private socketClient: SocketClientService,
  ) {
  }

  ngOnInit(): void {
    this.subscribeChangeStateDeploy();
    this.socketClient.state.pipe(filter(state => state === SocketClientState.ERROR), takeUntil(this.unsubscribeAll$)).subscribe(() => {
      this.subscribeChangeStateDeploy();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next(true);
    this.unsubscribeAll$.complete();
  }

  subscribeChangeStateDeploy(): void {
    this.socketClient.onMessage('/topic/deploy').pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (success: boolean) => {
        this.checkTimeDeployStorage();
        if (this.deployDialogRef && !success) {
          this.deployDialogRef.close();
          this.deployDialogRef = undefined;
        }
        if (success && !this.deployDialogRef && !this.offNoticeDeploy) {
          this.deployDialogRef = this.dialog.open(ModalDeployComponent, {
            panelClass: 'modal-deploy',
            disableClose: true,
          });
        } else {
          if (this.deployDialogRef) {
            this.deployDialogRef.close();
            this.deployDialogRef = undefined;
          }
        }
        if (localStorage.getItem(STORAGE_DEPLOY) && !success) {
          localStorage.removeItem(STORAGE_DEPLOY);
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
    this.socketClient.send('/app/deploy');

  }

  checkTimeDeployStorage(): void {
    if (localStorage.getItem(STORAGE_DEPLOY)) {
      const nowDate = new Date();
      const secondNowDate = Math.round(nowDate.setSeconds(nowDate.getSeconds()) / 1000);
      const dateStorage = localStorage.getItem(STORAGE_DEPLOY);
      if (dateStorage) {
        const secondStorage = Math.round(JSON.parse(dateStorage) / 1000);
        if (secondStorage + 900 > secondNowDate) {
          this.offNoticeDeploy = true;
        } else {
          this.offNoticeDeploy = false;
          localStorage.removeItem(STORAGE_DEPLOY);
        }
      }
    } else {
      this.offNoticeDeploy = false;
    }
  }
}
