import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-application',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule],
  templateUrl: './cancel-application.component.html',
  styleUrls: ['./cancel-application.component.scss'],
})
export class DialogCancelApplicationComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogCancelApplicationComponent>,
  ) {
  }
  closeDialog(value: boolean): void {
    this.dialogRef.close(value);
  }
}
