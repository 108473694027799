import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FastExchangeService } from '../../api/fast-exchange/fast-exchange.service';
import { pluck, exhaustMap, map, catchError, of, switchMap } from 'rxjs';
import { FastExchangeActions } from './fast-exchange.actions';
import { RateCommon, DirectionData, CommissionDataCommon, FERequest, GetDepositParams } from 'src/app/api/fast-exchange/fast-exchange.model';
import { ParamsFERate, ParamsFEDirectionData, ParamsFEValidateWallet, ParamsFECreate, ParamsFEGetDepositParams } from 'src/app/core/models/fast-exchange';

@Injectable()
export class FastExchangeEffects {
  constructor(
    private actions$: Actions, 
    private fastExchangeService: FastExchangeService
  ) { }

  checkExchangeDirections$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getDirectionIsPresent.type),
    pluck('payload'),
    exhaustMap(() =>
      this.fastExchangeService.getDirectionIsPresent().pipe(
        map((data: boolean) => FastExchangeActions.getDirectionIsPresentSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getDirectionIsPresentFailure()))
      )
    )
  ));

  getCurrentRate$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getCurrentRate.type),
    pluck('payload'),
    exhaustMap((params: ParamsFERate) =>
      this.fastExchangeService.getCurrentRate(params).pipe(
        map((data: RateCommon) => FastExchangeActions.getCurrentRateSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getCurrentRateFailure()))
      )
    )
  ));

  getDirectionsData$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getDirectionsData.type),
    pluck('payload'),
    switchMap((params: ParamsFEDirectionData) =>
      this.fastExchangeService.getDirectionsData(params).pipe(
        map((data: DirectionData[]) => FastExchangeActions.getDirectionsDataSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getCurrentRateFailure()))
      )
    )
  ));

  validateWallet$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getValidateAddress.type),
    pluck('payload'),
    exhaustMap((params: ParamsFEValidateWallet) =>
      this.fastExchangeService.getValidateAddress(params).pipe(
        map((data: boolean) => FastExchangeActions.getValidateAddressSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getValidateAddressFailure()))
      )
    )
  ));

  getCommissionData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FastExchangeActions.getCommissionData.type),
      switchMap(({ payload }) =>
        this.fastExchangeService.getCommissionData(payload).pipe(
          switchMap((data: CommissionDataCommon) => of(FastExchangeActions.getCommissionDataSuccess({ payload: data }))),
          catchError((error: HttpErrorResponse) => {
            return of(FastExchangeActions.getCommissionDataFailure({ error }));
          })
        )
      )
    );
  });

  confirmPayment$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.putConfirmPayment.type),
    pluck('payload'),
    exhaustMap((params: number) =>
      this.fastExchangeService.putConfirmPayment(params).pipe(
        map(() => FastExchangeActions.putConfirmPaymentSuccess({ payload: { confirm: true } })),
        catchError(() => of(FastExchangeActions.putConfirmPaymentFailure()))
      )
    )
  ));

  getRequestInfo$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getRequestInfo.type),
    pluck('payload'),
    exhaustMap((params: string) =>
      this.fastExchangeService.getRequestInfo(params).pipe(
        map((data: FERequest) => FastExchangeActions.getRequestInfoSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getRequestInfoFailure()))
      )
    )
  ));

  searchRequestInfo$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getSearchInfoRequest.type),
    pluck('payload'),
    exhaustMap((params: string) =>
      this.fastExchangeService.getSearchInfo(params).pipe(
        map((data: FERequest) => FastExchangeActions.getSearchInfoRequestSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getSearchInfoRequestFailure()))
      )
    )
  ));

  getDepositParams$ = createEffect(() => this.actions$.pipe(
    ofType(FastExchangeActions.getDepositParams.type),
    pluck('payload'),
    exhaustMap((params: ParamsFEGetDepositParams) =>
      this.fastExchangeService.getDepositParams(params).pipe(
        map((data: GetDepositParams) => FastExchangeActions.getDepositParamsSuccess({ payload: data })),
        catchError(() => of(FastExchangeActions.getDepositParamsFailure()))
      )
    )
  ));
}
