import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

import 'zone.js';  // Included with Angular CLI.
(window as any).global = window;
(window as any).globalThis = window;
