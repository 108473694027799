import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskContactInfo',
  standalone: true
})

export class maskContactInfoPipe implements PipeTransform {
  transform(str: string, type?: string, isVisible?: boolean): string | any {
    if (!isVisible) {
      switch (type) {
        case 'email':
          let email1 = str.slice(0, 2);
          let email2 = '***';
          let email3 = str.slice(str.indexOf('@'));
          let resEmail = email1 + email2 + email3;
          return resEmail;
        case 'card':
          let maskResCard;
          let card1 = str.slice(0, 2);
          let card2 = str.slice(2).split('').map(_ => '*').join('');
          let resCard = card1 + card2;
          if (resCard.length === 16) {
            maskResCard = resCard.slice(0, 4) + ' ' + resCard.slice(4, 8) + ' ' + resCard.slice(8, 12) + ' ' + resCard.slice(12);
            return maskResCard;
          } else {
            return resCard;
          }
        case 'address':
          let addressLength = str.length;
          let address1 = str.slice(0, 4);
          let address2 = '**************';
          let address3 = str.slice(addressLength - 2, addressLength);
          let resAddress = address1 + address2 + address3;
          return resAddress;
          
        case 'addressLong':
          if (str.length > 20) {
            let addressLongLength = str.length;
            let addressLong1 = str.slice(0, 10);
            let addressLong2 = '*******';
            let addressLong3 = str.slice(addressLongLength - 8, addressLongLength);
            let resAddressLong = addressLong1 + addressLong2 + addressLong3;
            return resAddressLong;
          } else {
            return str;
          }
      }
    } else {
      return str;
    }
  }
}
