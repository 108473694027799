<form [formGroup]="form" class="rounded-xl relative bg-main-dark-blue-500 px-[18px] py-6 2xl:px-8 2xl:py-9 min-h-[300px]">
  <ng-container *ngIf="!(isEnabledDirections$| async)">
    <div class="bg-main-dark-blue-500 absolute z-30 left-0 top-0 right-0 bottom-0 rounded-xl flex items-center justify-center text-center text-[20px]" [innerHTML]="'home.exchange_section.blocked_text' | translate"></div>
  </ng-container>
  <ng-container *ngIf="(isLoadedDirections$ | async) && !firstGetCommission; else templateForm">
    <div class="relative mb-4 flex flex-col gap-3">
      <div class="form-group flex gap-[5px]" [class.pointer-events-none]="eventNoneTypeRate || !isRateLoaded">
        <div class="form-control-row" [class.input-invalid]="form.controls['fromAmount'].errors || form.controls['fromAmount'].value === 'N/A'">
          <div class="form-control min-w-100perc-100px">
            <span class="font-bold text-[12px] xl:text-[18px] leading-none absolute top-[27px] left-[18px]" *ngIf="form.controls['fromAmount'].value === 'N/A'">N/A</span>
            <label class="form-label">{{ 'home.exchange_section.form.send' | translate }}</label>
            <input type="text" autocomplete="off" class="form-input-exchange min-w-100perc-100px w-auto" (input)="changesFromAmount()" formControlName="fromAmount" separatorLimit="10000000" [mask]="'separator.' + selectedToDirection?.from_merchant_currency.currency.scale" thousandSeparator=","/>
          </div>
          <div class="form-control relative min-w-[100px]">
            <div class="absolute left-0 top-0 z-[1] flex items-center py-3">
              <img class="flex h-[29px] w-[29px]" [src]="'assets/images/currency/' + selectedFromDirection?.selectData?.icon.toLowerCase() + '.svg'" [alt]="selectedFromDirection?.selectData?.currency" />
              <div class="ml-[5px] flex flex-col">
                <span class="mb-1 text-[8px] leading-none text-white opacity-50">{{ selectedFromDirection.selectData.merchant | transformNetworkToDefaultType: selectedFromDirection.selectData.currency }}</span>
                <span class="text-xs text-white leading-none">{{ selectedFromDirection.selectData.currency }}</span>
              </div>
            </div>
            <exchange-select [inputPanelClass]="'home-exchange-select-panel-class'" (selectValue)="exchangeSelectOutput($event, 'fromDirection')" [selectDataInput]="fromDirections" [selectedDirectionInput]="selectedFromDirection"></exchange-select>
          </div>
        </div>
      </div>
      <button [class.pointer-events-none]="eventNoneTypeRate || !isRateLoaded" class="absolute left-1/2 top-1/2 z-10 h-[42px] w-[42px] -translate-x-1/2 -translate-y-1/2" *ngIf="!isRateLoaded || (isReversed$ | async)" (click)="reverseExchange()">
        <div *ngIf="eventNoneTypeRate || !isRateLoaded; else templateIconreverse" class="bg-main-dark-blue-600 rounded-full w-full h-full flex items-center justify-center">
          <app-loader [sizeClass]="'large'"></app-loader>
        </div>
        <ng-template #templateIconreverse>
          <img *ngIf="isReversed$ | async" class="w-full" src="assets/icons/reverse.svg" alt="reverse" />
        </ng-template>
      </button>
      <div class="form-group flex gap-[5px]" [class.pointer-events-none]="eventNoneTypeRate || !isRateLoaded">
        <div class="form-control-row" [class.input-invalid]="form.controls['toAmount'].errors || form.controls['toAmount'].value === 'N/A'">
          <div class="form-control min-w-100perc-100px">
            <span class="font-bold text-[12px] xl:text-[18px] leading-none absolute top-[27px] left-[18px]" *ngIf="form.controls['toAmount'].value === 'N/A'">N/A</span>
            <span class="font-bold text-[12px] xl:text-[18px] leading-none absolute top-[27px] left-[18px]" *ngIf="(form.controls['typeRate'].value === TypeRate.FLOAT) && form.controls['toAmount'].value !== 'N/A'">~</span>
            <label class="form-label">{{ 'home.exchange_section.form.reseive' | translate }}</label>
            <input [class.pl-[30px]]="(form.controls['typeRate'].value === TypeRate.FLOAT) && form.controls['toAmount'].value !== 'N/A'" class="form-input-exchange min-w-100perc-100px w-auto" formControlName="toAmount" autocomplete="off" (input)="changesToAmount()" separatorLimit="10000000" type="text" [mask]="'separator.' + selectedToDirection?.to_merchant_currency.currency.scale" thousandSeparator=","/>
          </div>
          <div class="form-control relative min-w-[100px]">
            <div class="absolute left-0 top-0 z-[1] flex items-center py-3">
              <img class="flex h-[29px] w-[29px]" [src]="'assets/images/currency/' + selectedToDirection.selectData.icon.toLowerCase() + '.svg'" [alt]="selectedToDirection.selectData.currency" />
              <div class="ml-[5px] flex flex-col">
                <span class="mb-1 text-[8px] leading-none text-white opacity-50">{{ selectedToDirection.selectData.merchant | transformNetworkToDefaultType: selectedToDirection.selectData.currency }}</span>
                <span class="text-xs text-white leading-none">{{ selectedToDirection.selectData.currency }}</span>
              </div>
            </div>
            <exchange-select [inputPanelClass]="'home-exchange-select-panel-class'" (selectValue)="exchangeSelectOutput($event, 'toDirection')" [selectDataInput]="toDirections" [selectedDirectionInput]="selectedToDirection"></exchange-select>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="form.controls['fromAmount'].errors && (form.controls['fromAmount'].errors['min'] || form.controls['fromAmount'].errors['minLimit'] || form.controls['fromAmount'].errors['required'])" class="form-error-text">{{ 'errors.exchange.min' | translate }} 
      <span *ngIf="!form.controls['fromAmount'].errors['max']; else templateLoader" class="relative" (click)="setCommonSum()">
        <span class="underline hover:no-underline cursor-pointer">{{ selectedToDirection.common_amount }}</span>
        {{selectedFromDirection.selectData.currency}}
      </span>
    </p>
    <p *ngIf="form.controls['fromAmount'].errors && form.controls['fromAmount'].errors['max']" class="form-error-text">{{ 'errors.exchange.max' | translate }} 
      <span *ngIf="!form.controls['fromAmount'].errors['min'] && !form.controls['fromAmount'].errors['minLimit']; else templateLoader" (click)="setMaxSum()">
        <span class="underline hover:no-underline cursor-pointer">{{ selectedToDirection.max_amount }}</span>
        {{selectedFromDirection.selectData.currency}}
      </span>
    </p>
    <p *ngIf="form.controls['toAmount'].errors && (form.controls['toAmount'].errors['max'] || form.controls['toAmount'].errors['maxLimit']) && !form.controls['fromAmount'].errors" class="form-error-text">{{ 'errors.exchange.reserve' | translate }} 
      <span *ngIf="!form.controls['toAmount'].errors['min']; else templateLoader" >
        {{ selectedToDirection.reserved_balance }}  
        {{selectedToDirection.selectData.currency}}
      </span>
    </p>
    <p *ngIf="form.controls['toAmount'].errors && form.controls['toAmount'].errors['required'] && !form.controls['fromAmount'].errors" class="form-error-text">{{ 'errors.exchange.required_to_amount' | translate }}</p>
    <div class="mb-5 2xl:flex 2xl:items-center 2xl:justify-between">
      <div class="mb-[12px] text-sm 2xl:mb-0 flex items-center">
        <span class="mr-2 text-white opacity-50">{{ 'home.exchange_section.form.rate' | translate }}</span>
        <span class="text-base font-bold leading-none text-white flex">
          {{currentRate?.front_rate}} 
          <span class="text-main-purple-400 text-[12px] ml-1">{{ 'home.exchange_section.' + (form.getRawValue().typeRate.toLowerCase()) | translate }}</span>
        </span>
      </div>
      <div class="flex gap-2">
        <span class="w-[37px] h-[37px]">
          <img class="w-full cursor-pointer opacity-70 hover:opacity-100 transition-all" [ngClass]="(eventNoneTypeRate || !isRateLoaded) ? 'pointer-events-none !opacity-40' : ''" (click)="(rateCommon?.FIXED && rateCommon?.FLOAT) && changeTypeRate(TypeRate.FLOAT, form.controls['typeRate'].disabled)" *ngIf="form.getRawValue().typeRate === TypeRate.FIXED" src="assets/images/svg/icon-lock.svg" alt="lock"
          [matTooltip]="'page_exchange.tooltip.' + (rateCommon?.FIXED && rateCommon?.FLOAT ? 'rates_common' : currentRate?.rate_type === 'FLOAT' ? 'course_float' : (((rateCommon?.FIXED && !rateCommon?.FLOAT) || (selectedToDirection?.from_merchant_currency.currency.symbol | isExchangeStablecoinToStablecoin: selectedToDirection?.to_merchant_currency.currency.symbol)) ? 'rate_fixed_stable' : 'course_fixed')) | translate" 
          matTooltipPosition="below">
          <img class="w-full cursor-pointer opacity-70 hover:opacity-100 transition-all" [ngClass]="(eventNoneTypeRate || !isRateLoaded) ? 'pointer-events-none !opacity-40' : ''" (click)="(rateCommon?.FIXED && rateCommon?.FLOAT) && changeTypeRate(TypeRate.FIXED, form.controls['typeRate'].disabled)" *ngIf="form.getRawValue().typeRate === TypeRate.FLOAT" src="assets/images/svg/icon-unlock.svg" alt="unlock"
          [matTooltip]="'page_exchange.tooltip.' + (rateCommon?.FIXED && rateCommon?.FLOAT ? 'rates_common' : currentRate?.rate_type === 'FLOAT' ? 'course_float' : (((rateCommon?.FIXED && !rateCommon?.FLOAT) || (selectedToDirection?.from_merchant_currency.currency.symbol | isExchangeStablecoinToStablecoin: selectedToDirection?.to_merchant_currency.currency.symbol)) ? 'rate_fixed_stable' : 'course_fixed')) | translate" 
          matTooltipPosition="below">
        </span>
        <span class="relative w-[37px] h-[37px] more-info">
          <img class="w-full cursor-pointer opacity-70 hover:opacity-100 transition-all" src="assets/images/svg/more-info.svg" alt="info">
          <div class="more-info-data absolute -left-[46px] top-full z-20 w-[276px] max-w-[276px] rounded-b-lg bg-main-dark-blue-500 p-[17px] 2xl:left-auto 2xl:right-0 2xl:min-w-[276px] hidden">
            <h5
              class="relative mb-[14px] pb-[14px] text-left text-sm text-white after:absolute after:bottom-0 after:left-0 after:block after:h-[1px] after:w-full after:bg-white after:opacity-20 after:content-['']">
              {{ 'home.exchange_section.form.exchange_info' | translate }}
            </h5>
            <ul class="mb-[14px] flex flex-col gap-[18px]">
              <li class="flex items-center justify-between">
                <span class="text-xs leading-none text-white opacity-50">{{ 'home.exchange_section.form.exchange_fee' | translate }}</span>
                <span class="text-xs font-semibold leading-none text-main-purple-400">{{commissionData?.exchange_value_commission}} {{selectedToDirection?.to_merchant_currency.currency.symbol}}</span>
              </li>
              <li class="flex items-center justify-between relative after:content-[''] after:absolute after:-bottom-2 after:left-0 after:w-full after:h-[1px] after:bg-white-opacity-20">
                <span class="text-xs leading-none text-white opacity-50">{{ 'home.exchange_section.form.network_fee' | translate }}</span>
                <span class="text-xs font-semibold leading-none text-main-purple-400">{{commissionData?.merchant_value_commission}} {{selectedToDirection?.to_merchant_currency.currency.symbol}}</span>
              </li>
              <li class="flex items-center justify-between">
                <span class="text-xs leading-none text-white opacity-50">{{ 'home.exchange_section.form.total_fee' | translate }}</span>
                <span class="text-xs font-semibold leading-none text-main-purple-400">{{commissionData?.total_commission}} {{selectedToDirection?.to_merchant_currency.currency.symbol}}</span>
              </li>
              <li class="flex items-center justify-between">
                <span class="text-xs leading-none text-white opacity-50">{{ 'home.exchange_section.form.processing_time' | translate }}</span>
                <span class="text-xs font-semibold leading-none text-main-purple-400">
                  {{'shared.commission.' + (commissionData?.commission_type?.toLowerCase()) | translate}}
                </span>
              </li>
            </ul>
            <p class="rounded-[5px] bg-main-dark-blue-300 px-[10px] py-2 text-left text-[12px]">
              <span class="text-white opacity-50">{{ 'home.exchange_section.form.commission' | translate }}</span>
            </p>
          </div>
        </span>
      </div>
    </div>          
    <div class="form-group flex flex-col relative gap-[5px] mb-5" *ngIf="selectedToDirection?.to_merchant_currency.currency.type === 'CRYPTO' && selectedToDirection?.from_merchant_currency.currency.type === 'CRYPTO'">
      <label class="form-label z-10">{{ 'page_exchange.exchange.fee' | translate }}</label>
      <div class="form-control-row" [class.pointer-events-none]="eventNoneTypeRate || !isRateLoaded" [class.select-indalid]="submitted && form.controls['typeCommission'].errors">
        <mat-select formControlName="typeCommission" [placeholder]="'page_exchange.exchange.placeholders.select_commission' | translate" class="commission-select" [panelClass]="'commission-panel-class'">
          <mat-option *ngIf="commissionDataCommon?.RECOMMENDED" [value]="'RECOMMENDED'">
            <span class="font-bold">{{commissionDataCommon?.RECOMMENDED?.merchant_value_commission}} {{selectedToDirection.to_merchant_currency.currency.symbol}}</span>
            <span class="opacity-50">{{ 'shared.commission.select_recommended' | translate }}</span>
          </mat-option>
          <mat-option *ngIf="commissionDataCommon?.STANDARD" [value]="'STANDARD'">
            <span class="font-bold">{{commissionDataCommon?.STANDARD?.merchant_value_commission}} {{selectedToDirection.to_merchant_currency.currency.symbol}}</span> 
            <span class="opacity-50">{{ 'shared.commission.select_standard' | translate }}</span>
          </mat-option>
          <mat-option *ngIf="commissionDataCommon?.SERVICE" [value]="'SERVICE'" [ngClass]="!commissionDataCommon?.SERVICE?.available ? 'pointer-events-none' : ''">
            <span [class.opacity-50]="!commissionDataCommon?.SERVICE?.available" class="font-bold">{{commissionDataCommon?.SERVICE?.merchant_value_commission}} {{selectedToDirection.to_merchant_currency.currency.symbol}}</span> 
            <span [class.!opacity-30]="!commissionDataCommon?.SERVICE?.available" class="opacity-50">{{ 'shared.commission.select_service' | translate }}</span>
            <span class="absolute text-red-500 top-0 right-1 text-[10px]" *ngIf="!commissionDataCommon?.SERVICE?.available">{{ 'shared.commission.temporality_unavailable' | translate }}</span>
          </mat-option>
        </mat-select>
      </div>
      <p *ngIf="submitted && form.controls['typeCommission'].errors && form.controls['typeCommission'].errors['required']" class="form-error-text mt-2">{{ 'contact_page.form.errors.required' | translate }}</p>
    </div>
    <button [disabled]="commissionLoading" (click)="goToExchange()" class="btn btn-primary w-full max-w-full">{{ 'home.exchange_section.form.exchange' | translate }}</button>
  </ng-container>
</form>

<ng-template #templateForm>  
  <div class="rounded-xl bg-main-dark-blue-500 absolute left-0 top-0 w-full h-full z-30 flex items-center justify-center">
  <app-loader [sizeClass]="'block'"></app-loader>
</div>
</ng-template>

<ng-template #templateLoader>
  <app-loader></app-loader>
</ng-template>
