import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { ExchangeSectionComponent } from './components/exchange-section/exchange-section.component';
import { RemainingTransactionsComponent } from './components/remaining-transactions/remaining-transactions.component';
import { SwiperModule } from 'swiper/angular';
import { BecomePartnerComponent } from '../../../shared/components/become-partner/become-partner.component';
import { OurAdvantagesComponent } from './components/our-advantages/our-advantages.component';
import { ExchangeBlockComponent } from './components/exchange-section/components/exchange-block/exchange-block.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { IntegrateSctionComponent } from './components/integrate-sction/integrate-sction.component';
import { ExchangeSelectComponent } from 'src/app/shared/components/exchange-select/exchange-select.component';
import { NgxMaskDirective } from 'ngx-mask';
import { LoaderComponent } from 'src/app/shared/components/loader/loader.component';
import { MatSelectModule } from '@angular/material/select';
import { TransformNetworkToDefaultTypePipe } from 'src/app/shared/pipes/transform-network-to-default-type.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsExchangeStablecoinToStablecoin } from 'src/app/shared/pipes/is-exchange-stablecoin-to-stablecoin.pipe';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
];

@NgModule({
  declarations: [HomeComponent, ExchangeSectionComponent, RemainingTransactionsComponent, OurAdvantagesComponent, ExchangeBlockComponent, IntegrateSctionComponent],
  imports: [
    ExchangeSelectComponent,
    CommonModule,
    RouterModule.forChild(routes),
    SwiperModule,
    BecomePartnerComponent,
    LoaderComponent,
    NgOptimizedImage,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatTableModule,
    NgxMaskDirective,
    TransformNetworkToDefaultTypePipe,
    MatTooltipModule,
    IsExchangeStablecoinToStablecoin
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
