<section class="pt-[70px] lg:pt-[80px] xl:pt-[120px]">
  <div class="container">
    <div class="flex flex-col items-center rounded-[20px] bg-main-purple-500 bg-become-partner bg-cover bg-center bg-no-repeat px-[20px] py-[35px] md:py-[40px] lg:pb-[50px] lg:pt-[68px]">
      <div class="flex max-w-[800px] flex-col items-center gap-[24px] text-center lg:gap-[38px]">
        <span class="text-[36px] font-bold leading-[1] lg:text-[42px] xl:text-[52px]">
          {{ 'shared.become_partner.title' | translate }}
        </span>
        <p class="text-[16px] text-white text-opacity-70 lg:text-[21px]">
          {{ 'shared.become_partner.text' | translate }}
        </p>
        <a target="_blank" href="#" class="btn btn-black min-w-full md:min-w-[168px]">
          {{ 'shared.become_partner.btn_text' | translate }}
        </a>
      </div>
    </div>
  </div>
</section>
