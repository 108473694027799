<ng-container [ngSwitch]="currentExchangeApplication">
  <ng-container *ngSwitchCase="ExchangeApplication.deposit">
    <deposit-application [requestDataInput]="requestData"></deposit-application>
  </ng-container>
  <ng-container *ngSwitchCase="ExchangeApplication.status">
    <status-application [requestDataInput]="requestData"></status-application>
  </ng-container>
  <ng-container *ngSwitchCase="ExchangeApplication.cancel">
    <not-found-application></not-found-application>
  </ng-container>
  <ng-container *ngSwitchCase="ExchangeApplication.loader">
    <div class="flex min-w-[80vw] flex-col items-center md:min-w-[566px]">  
      <div class="z-[2] mb-[-43px] flex h-[60px] w-[60px] flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-800 sm:h-[86px] sm:w-[86px]">
      <span (click)="closeDialog()" class="flex h-[44px] w-[44px] cursor-pointer flex-col items-center justify-center rounded-[50%] bg-main-dark-blue-300 p-[14px] sm:h-[64px] sm:w-[64px] sm:p-0">
        <img ngSrc="assets/images/svg/icons/close-icons.svg" alt="" height="24" width="24" />
      </span>
    </div>
      <div class="flex w-full flex-col rounded-[30px] bg-main-dark-blue-300 px-[25px] pb-[25px] pt-[50px] text-[12px] sm:px-[40px] sm:pb-[40px] sm:pt-[65px] sm:text-[16px] min-h-[600px]">
        <app-loader [sizeClass]="'block'"></app-loader>
      </div>
    </div>
  </ng-container>
</ng-container>
