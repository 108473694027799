import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-become-partner',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './become-partner.component.html',
  styleUrls: ['./become-partner.component.scss'],
})
export class BecomePartnerComponent {}
