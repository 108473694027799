import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DialogExchangeApplicationComponent } from '../../exchange-application.component';
import { FERequest } from 'src/app/api/fast-exchange/fast-exchange.model';
import { DepositStatusFE, RequestStatusFE, SubMerchant, WithdrawStatusFE } from 'src/app/core/enums/fast-exchange.enum';
import { Router } from '@angular/router';
import { SocketClientService } from 'src/app/core/services/socket.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { TransformNetworkToDefaultTypePipe } from 'src/app/shared/pipes/transform-network-to-default-type.pipe';
import { FERequestStatusTitle } from 'src/app/shared/pipes/fe-request-status-title.pipe';
import { TransformCurrencyWithNetworkToTokenPipe } from 'src/app/shared/pipes/transform-currency-with-network-to-token.pipe';
import { ClipboardModule } from 'ngx-clipboard';
import { maskContactInfoPipe } from 'src/app/shared/pipes/mask-contact-info.pipe';

@Component({
  selector: 'status-application',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ClipboardModule, TranslateModule, TransformNetworkToDefaultTypePipe, FERequestStatusTitle, TransformCurrencyWithNetworkToTokenPipe, maskContactInfoPipe],
  templateUrl: './status-application.component.html',
  styleUrls: ['./status-application.component.scss'],
})
export class StatusApplicationComponent implements OnDestroy {
  @Input() set requestDataInput(data: FERequest | any) {
    this.requestData = data;
    console.log(data);
    if (data && data.withdraw_request_status === 'COMPLETED') {
      this.subscribeTxid();
    }
  }  
  SubMerchant = SubMerchant;
  txid: string | null = null;

  requestData: FERequest | any;

  private txid$: Subscription = new Subscription;
  private unsubscribe$ = new Subject<boolean>();
  constructor(
    public dialogRef: MatDialogRef<DialogExchangeApplicationComponent>,
    private router: Router,
    private socketClient: SocketClientService,
  ) {
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.txid$ && this.txid$.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
    if (this.requestData.status === 'AUTO_REVOKED' || this.requestData.status === 'REVOKED_BY_ADMIN' || this.requestData.status === 'REVOKED_BY_ACCOUNT' || this.requestData.status === 'EXPIRED' || (this.requestData.status === 'COMPLETED' && this.txid)) {
      this.router.navigate(['/'], { queryParams: {} });
    }
  }

  private subscribeTxid(): void {
    this.txid$ && this.txid$.unsubscribe();
    this.txid$ = this.socketClient.onPlainMessage(`/topic/cryptoWithdrawHash.${this.requestData.id}`)
      .pipe(takeUntil(this.unsubscribe$)).subscribe({
        next: (value: string) => {
          console.log('hash', value);
          this.txid = value;
        }
      });
    this.socketClient.send(`/app/cryptoWithdrawHash.${this.requestData.id}`);
  }

  get statusRevoked() {
    return this.requestData.status === RequestStatusFE.AUTO_REVOKED ||
      this.requestData.status === RequestStatusFE.REVOKED_BY_ADMIN ||
      this.requestData.status === RequestStatusFE.REVOKED_BY_ACCOUNT ||
      this.requestData.deposit_request_status === DepositStatusFE.AUTO_REVOKED ||
      this.requestData.deposit_request_status === DepositStatusFE.REVOKED_BY_ADMIN ||
      this.requestData.deposit_request_status === DepositStatusFE.REVOKED_BY_ACCOUNT ||
      this.requestData.withdraw_request_status === WithdrawStatusFE.AUTO_REVOKED ||
      this.requestData.withdraw_request_status === WithdrawStatusFE.REVOKED_BY_ADMIN ||
      this.requestData.withdraw_request_status === WithdrawStatusFE.REVOKED_BY_ACCOUNT;
  }

  get statusExpired() {
    return this.requestData.status === RequestStatusFE.EXPIRED && this.requestData.deposit_request_status === DepositStatusFE.EXPIRED;
  }

  get statusInProgress() {
    return this.requestData.status === RequestStatusFE.IN_PROCESS && this.requestData.deposit_request_status === DepositStatusFE.IN_PROCESS;
  }

  get statusCreated() {
    return this.requestData.status === RequestStatusFE.CREATED && this.requestData.deposit_request_status === DepositStatusFE.CREATED;
  }

  get statusDepositFailed() {
    return this.requestData.deposit_request_status === DepositStatusFE.AUTO_REVOKED ||
      this.requestData.deposit_request_status === DepositStatusFE.REVOKED_BY_ADMIN ||
      this.requestData.deposit_request_status === DepositStatusFE.REVOKED_BY_ACCOUNT || this.statusExpired;
  }

  get statusDepositInProgress() {
    return this.requestData.deposit_request_status === DepositStatusFE.PAID_UNCONFIRMED && !this.statusDepositFailed;
  }

  get statusDepositSuccess() {
    return this.requestData.deposit_request_status === DepositStatusFE.COMPLETED;
  }

  get statusWithdrawFailed() {
    return this.statusRevoked || this.statusExpired;
  }

  get statusWithdrawInProgress() {
    return this.requestData.withdraw_request_status !== WithdrawStatusFE.COMPLETED && !this.statusWithdrawFailed && !this.statusDepositInProgress;
  }

  get statusWithdrawSuccess() {
    return this.requestData.withdraw_request_status === WithdrawStatusFE.COMPLETED;
  }
}
